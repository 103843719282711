<template>
    <div id="container">
        <head-comp></head-comp>
        <div id="back">
            <back-button @click="back"></back-button>
            <h2>Notifications</h2>
        </div>
        <div class="divider"></div>
        <div id="loader" v-if="Loading">
            <loader-comp></loader-comp>
        </div>
        <div v-else>
            <div v-if="Message==0">
                <div id="notifications" v-if="Notifications.length>0">
                <notification-comp v-for="notification,index in Notifications" :key="index" :title="notification.notificationSubject" :message="notification.notificationMessage" :time="notification.createdAt"></notification-comp>
             </div>
             <div v-else id="empty-list">
                <empty-list msg="No new Notifications"></empty-list>
             </div>
            </div>
            <div v-if="Message==1" id="empty-list">
                <crash-message msg="Something went wrong at our end"></crash-message>
            </div>
            <div v-if="Message==2" id="empty-list">
                <failed-message msg="Failed to fetch notifications"></failed-message>
            </div>
        </div>
    
    </div>
</template>

<script>
import HeadComp from '../../components/deliveries/head-comp.vue'
import BackButton from '../../components/general/back-button.vue'
import NotificationComp from '../../components/general/notification-comp.vue'
import LoaderComp from  '../../components/general/loader-comp.vue'
import Cookies from 'js-cookie'
import EmptyList from '../../components/messages/empty-comp.vue'
import CrashMessage from '../../components/messages/server-crash.vue'
import FailedMessage from '../../components/messages/error-comp.vue'
export default {
    components:{HeadComp,BackButton,NotificationComp,LoaderComp,EmptyList,CrashMessage,FailedMessage},
    data(){
        return{
            notifications:[],
            message:0
        }
    },
    computed:{
        Notifications(){
            return this.notifications
        },
        Loading(){
        return this.$store.getters['user/getLoading']
    },
    Message(){
        return this.message;
    }
    },
    methods:{
        back(){
            this.$router.go(-1)
        },
    },
    async mounted(){
        if(!Cookies.get('authenticated')){
            this.$router.push({name:'landing'})
        }
        this.$store.commit('user/setLoading',{value:true})
        let res = await this.$store.dispatch('user/fetchNotifications')
        this.$store.commit('user/setLoading',{value:false})
        if(res.status==200){
            this.notifications=res.data
        }
        else if(res.status==500){
            this.message=1
        }
        else{
           this.message=2
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#container{
    width:100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y:auto ;
    position: absolute;
    top:0;
    left:0;
    padding-bottom:8vh;
}
#container::-webkit-scrollbar{
    display: none;
}
#empty-list{
    width: 100vw;
    margin-top:20vh;
    display: flex;
    justify-content: center;
}
@media only screen and (max-width:480px){
    .divider{
        display: none
     }
     #back{
         margin-left:3vw;
         margin-top:2vh;
         display: flex;
         align-items: center;
     }
     #back h2{
      margin-left:10px;color: #333;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.2px;
     }
     #notifications{
        width:96vw;
        margin-left:2vw;
        margin-right:2vw;
        height:fit-content;
        margin-top:2vh;
        padding-top:2vh;
        padding-bottom:3vh;
        max-height:67vh;
        overflow-y: auto;
    }
    #notifications::-webkit-scrollbar{
        display: none;
    }
    #loader{
        width:100vw;
        height: 60vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    .divider{
        display: none;
    }
    #back{
        margin-left:3.5vw;
        margin-top:2vh;
        margin-bottom:2vh;
        display: flex;
        align-items: center;
    }
    #back h2{
        margin-left:10px;
        color: #333;
font-family: Inter;
font-size: 23px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
    }
    #notifications{
        width:90vw;
        margin-left:5vw;
        margin-right:5vw;
        height:fit-content;
        padding-top:1vh;
        padding-bottom:3vh;
        max-height:70vh;
        overflow-y: auto;
    }
    #notifications::-webkit-scrollbar{
        display: none;
    }
    #loader{
        width:100vw;
        height: 60vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media only screen and (min-width:961px){
    .divider{
        height: 1px;
        background: rgba(80, 78, 78, 0.375);
        width:96vw;
        margin-left:2vw;
        margin-top:10px;
        margin-bottom:2vh;
    }
    #back{
        margin-left:2vw;
        margin-top:2vh;
        display: flex;
        align-items: center;
    }
    #back h2{
        margin-left:10px;
        color: #333;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
    }
    #notifications{
        width:70vw;
        margin-left:15vw;
        margin-right:15vw;
        height:fit-content;
        padding-top:2vh;
        padding-bottom:3vh;
        max-height:67vh;
        overflow-y: auto;
    }
    #notifications::-webkit-scrollbar{
        display: none;
    }
    #loader{
        width:100vw;
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>