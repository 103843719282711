<template>
    <div id="week-review">
      <div id="back">
        <back-button @click="back"></back-button>
      </div>
      <dues-modal v-if="DuesModal"></dues-modal>
        <confirm-modal v-if="ConfirmModal"></confirm-modal>
      <review-modal v-if="Modal" :value="review"></review-modal>
      <h2 id="weekNumber">Week{{ week }}</h2>
      <div id="underline"></div>
      <div id="loader1" v-if="Loading">
        <loader-comp></loader-comp>
      </div>
      <div id="table" v-else>
        <div id="headings">
            <div class="heading">Order ID</div>
            <div class="heading">Payout Amount</div>
            <div class="heading">Payment Mode</div>
            <div class="heading">Category</div>
            <div class="heading">Driver Name</div>
            <div class="heading">Date</div>
            <div class="heading">Review</div>
        </div>
    <div class="divider"></div>
    <div id="data" v-if="Message==0">
       <div  v-for="payment,index in Payments" :key="index">
        <div class="row">
            <div class="row-field">{{ payment.orderId}}</div>
            <div class="row-field">£{{ payment.orderValue }}</div>
            <div class="row-field">{{ payment.modeOfPayment }}</div>
            <div class="row-field">{{ payment.category }}</div>
            <div class="row-field" v-if="payment.driverName">{{ payment.driverName}}</div>
            <div class="row-field" v-else>.....</div>
            <div class="row-field" v-if="payment.orderDate">{{ payment.orderDate}}</div>
            <div class="row-field" v-else>....</div>
            <div class="review" @click="toggleModal(payment)">Review</div>
        </div>
        <div class="divider-row"></div>
       </div>
    </div>
      <div class="message-comp" v-if="Message==1">
           <empty-list msg="No payment done yet"></empty-list>
      </div>
      <div class="message-comp" v-if="Message==2">
        <server-crash msg="Something went wrong at our end"></server-crash>
      </div>
      <div class="message-comp" v-if="Message==3">
        <error-message msg="Failed to fetch week data"></error-message>
      </div>
      </div>
      <!-- <out-standing :Month="month" :Week="week"></out-standing> -->
    </div>
</template>

<script>
import BackButton from '../../components/general/back-button.vue'
//import OutStanding from '../../components/payment/week-outstanding.vue'
import ReviewModal from '../../components/payment/review-modal.vue'
import ConfirmModal from '../../components/payment/modals/confirmation-modal.vue'
import DuesModal from '../../components/payment/modals/week-dues.vue'
import LoaderComp from '../../components/general/loader-comp.vue'
import EmptyList from '../../components/messages/empty-comp.vue'
import ServerCrash from '../../components/messages/server-crash.vue'
import ErrorMessage from '../../components/messages/error-comp.vue'
import Cookies from 'js-cookie'
export default {
    components:{BackButton,ReviewModal,ConfirmModal,DuesModal,LoaderComp,EmptyList,ServerCrash,ErrorMessage},
    data(){
        return{
            week:'',
            month:'',
            review:'',
            loader:false,
            message:0
        }
    },
    methods:{
        back(){
            this.$router.push({name:'home'})
        },
        toggleModal(value){
            this.review = {
                orderNo:value.orderId,
                orderValue:'£ ' + value.orderValue,
                paymentMode:value.modeOfPayment,
                orderItems:'Item1,Item2,Item3,Item4',
                deliveryAddress:'The address where delivery was made',
                customerName:value.customerName || '....',
                deliveredBy:value.driverName || '....',
                category:value.category
            }
            this.$store.commit('payments/setReviewModal',{value:true})
        },
        ISoConverter(monthString){
        const months = [
    "January", "February", "March", "April",
    "May", "June", "July", "August",
    "September", "October", "November", "December"
  ];

  const monthIndex = months.findIndex(month => month.toLowerCase() === monthString.toLowerCase());

  if (monthIndex === -1) {
    return ''
  }
  const currentYear = new Date().getFullYear();
  const isoDate = new Date(currentYear, monthIndex+1, 1).toISOString();

  return isoDate;
    }
    },
    computed:{
        Modal(){
            return this.$store.getters['payments/getReviewModal']
        },
        DuesModal(){
            return this.$store.getters['payments/getModal']
        },
        ConfirmModal(){
            return this.$store.getters['payments/getConfirmationModal']
        },
        Payments(){
            return this.$store.getters['payments/getWeek']
        },
        Loading(){
            return this.loader
        },
        Message(){
            return this.message
        }
    },
    async mounted(){
        if(!Cookies.get('authenticated')){
            this.$router.push({name:'landing'})
        }
        this.month = this.$route.params.month;
        this.week = this.$route.params.week;
        if(this.week<1 || this.week>5){
            this.$router.go(-1)
        }
        this.loader = true
        let isoMonth  = this.ISoConverter(this.month)
        let res = await this.$store.dispatch('payments/billing',{value:isoMonth})
        if(res.status==200){
            let key =`week${this.week}`
            let orders = res.data[key]
            if(orders.length==0){
                this.message=1
            }
            else{
                this.$store.commit('payments/setWeek',{value:orders})
            }
        }
        else if(res.status==500){
            this.message=2
        }
        else{
            this.message=3
        }
        this.loader = false
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
.message-comp{
    display: flex;
    justify-content: center;
}
@media only screen and (max-width:480px){
    #week-review{
        width:100vw;
        padding-bottom:4vh;
    }
    #back{
        margin-top:2.5vh;
        margin-left:2.5vw;
    }
    #weekNumber{
        color: var(--Brand-color-2, #413F3F);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-top:30px;
        margin-left:3vw;
    }
    #underline{
        width: 74.396px;
height: 2.555px;
flex-shrink: 0;
border-radius: 6px;
background: var(--Brand-color-3, #8D8D8D);
margin-top:2px;
margin-left:3vw;
    }
    #table{
        margin-top:2vh;
        width:100vw;
        padding-left:2vw;
        padding-right:2vw;
        overflow-x:auto;
        height: fit-content;
        max-height: 80vh;
        padding-top:2vh;
        padding-bottom:2vh;
        overflow:auto ;
    }
    #loader1{
        margin-top:2vh;
        width:100vw;
        padding-left:2vw;
        padding-right:2vw;
        overflow-x:auto;
        height: fit-content;
        max-height: 60vh;
        padding-top:2vh;
        padding-bottom:2vh;
        overflow:auto ;
        display: flex;
        justify-content: center;
        margin-top:20vh;
    }
    #table::-webkit-scrollbar{
        display: none;
    }
    #headings{
        display: grid;
        width:96vw;
        grid-template-columns: repeat(6,25vw) 30vw;
    }
    .heading{
        color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
text-align: center;
word-wrap: break-word;
    }
.divider{
    height:1px;
    background: #00000097;
    width:196vw;
    margin-left:2vw;
    margin-top:7.5px;
    margin-bottom:7.5px;
}
#data{
    width:96vw;
}
.row{
    display: grid;
        width:fit-content;
        overflow-x:auto ;
        grid-template-columns: repeat(6,25vw) 30vw;
        padding-left:2vw;
}
.divider-row{
    height:1px;
    background: #00000097;
    width:196vw;
    margin-left:2vw;
    margin-top:7.5px;
    margin-bottom:7.5px;
}
.row-field{
    color: var(--Brand-color-2, #413F3F);
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
word-wrap: break-word;
}
.review{
    border-radius: 8px;
background: #25AD34;
width:20vw;
height:45px;
margin-left:2vw;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 45px;
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #back{
        margin-top:2.5vh;
        margin-left:2.5vw;
    }
    #weekNumber{
        color: var(--Brand-color-2, #413F3F);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-top:30px;
        margin-left:3vw;
    }
    #underline{
        width: 94.396px;
height: 2.555px;
flex-shrink: 0;
border-radius: 6px;
background: var(--Brand-color-3, #8D8D8D);
margin-top:3px;
margin-left:3vw;
    }
#table{
    margin-top:2vh;
    width:100vw;
    padding-left:3vw;
    padding-right:3vw;
    overflow:auto;
    height:80vh;
    padding-top:2vh;
    padding-bottom:2vh;
    overflow:auto ;
}
#loader1{
    margin-top:2vh;
    width:100vw;
    padding-left:3vw;
    padding-right:3vw;
    overflow:auto;
    height:60vh;
    padding-top:2vh;
    padding-bottom:2vh;
    overflow:auto ;
    display: flex;
    justify-content: center;
    margin-top:20vh
}
#table::-webkit-scrollbar{
    display: none;
}
#headings{
    display: grid;
    width:94vw;
    grid-template-columns:  repeat(2,16vw) repeat(2,19vw) repeat(2,22vw) 20vw;
}

.heading{
    color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
text-align: center;
word-wrap: break-word;
}
.divider{
height:1px;
background: #00000097;
width:130vw;
margin-left:2vw;
margin-top:12.5px;
margin-bottom:12.5px;
}
#data{
    width:fit-content;
    overflow: auto;
}
#data::-webkit-scrollbar{
    display: none;
}
.row{
    display: grid;
    width:fit-content;
    grid-template-columns: repeat(2,16vw) repeat(2,19vw) repeat(2,22vw) 20vw;
    padding-left:2vw;
}
.divider-row{
    height:1px;
    background: #00000097;
    width:fill;
    margin-left:2vw;
    margin-top:12.5px;
    margin-bottom:12.5px;
}
.row-field{
    color: var(--Brand-color-2, #413F3F);
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
word-wrap: break-word;
}
.review{
    border-radius: 8px;
background: #25AD34;
width:20vw;
height:45px;
margin-left:2vw;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 45px;
}
}
@media only screen and (min-width:961px){
    #back{
        margin-top:2.5vh;
        margin-left:1.5vw;
    }
    #weekNumber{
        color: var(--Brand-color-2, #413F3F);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-top:30px;
        margin-left:2vw;
    }
    #underline{
        width: 94.396px;
height: 2.555px;
flex-shrink: 0;
border-radius: 6px;
background: var(--Brand-color-3, #8D8D8D);
margin-top:3px;
margin-left:2vw;
    }
    #table{
        margin-top:3vh;
        width:100vw;
        padding-left:3vw;
        padding-right:3vw;
        overflow-y:scroll;
        overflow-x: hidden;
        height: fit-content;
        max-height: 80vh;
        padding-top:2vh;
        padding-bottom:2vh;
        overflow-x:hidden ;
    }
    #loader1{
        margin-top:3vh;
        width:100vw;
        padding-left:3vw;
        padding-right:3vw;
        overflow-y:scroll;
        overflow-x: hidden;
        height: fit-content;
        max-height: 65vh;
        padding-top:2vh;
        padding-bottom:2vh;
        overflow-x:hidden ;
        display: flex;
        justify-content: center;
        margin-top:20vh;
    }
    #table::-webkit-scrollbar{
        display: none;
    }
    #headings{
        display: grid;
        width:94vw;
        overflow-x:hidden ;
        padding-left:2vw;
        padding-right:2vw;
        grid-template-columns: repeat(2,12vw) repeat(2,9vw) repeat(2,14vw) 16vw;
    }
    .heading{
        color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
    word-wrap: break-word;
    }
    .divider{
    height:1px;
    background: #00000097;
    width:90vw;
    margin-left:1vw;
    margin-top:10.5px;
    margin-bottom:10.5px;
    }
    #data{
        width:94vw;
        overflow-x:hidden;
    }
    .row{
        display: grid;
        width:94vw;
        padding-left:2vw;
        padding-right:2vw;
        grid-template-columns: repeat(2,12vw) repeat(2,9vw) repeat(2,14vw) 16vw;
    }
    .divider-row{
        height:1px;
        background: #00000097;
        width:90vw;
        margin-left:1vw;
        margin-top:10.5px;
        margin-bottom:10.5px;
    }
    .row-field{
        color: var(--Brand-color-2, #413F3F);
    text-align: center;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    word-wrap: break-word;
    }
    .review{
        border-radius: 8px;
    background: #25AD34;
    width:10vw;
    height:45px;
    margin-left:5vw;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    cursor: pointer;
    }
}
</style>