<template>
    <div id="notification-card" class="notification-green">
    <div id="notification-1"></div>
    <div id="notification-2">
        <div id="notification-text">
            <h2>{{ Title }}</h2>
            <h3>{{ Body }}</h3>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none" id="notification-close" @click="close">
            <line x1="1.43951" y1="1.46967" x2="14.4395" y2="14.4696" stroke="#8D8D8D" stroke-width="1.5"/>
            <line x1="1.56049" y1="14.4697" x2="14.5605" y2="1.4697" stroke="#8D8D8D" stroke-width="1.5"/>
          </svg>
    </div>
    </div>
</template>

<script>
export default {
    computed:{
        Title(){
            return this.$store.getters['user/getNotificationData'].title;
        },
        Body(){
            let val = this.$store.getters['user/getNotificationData'].body
            if(val.length>25){
                val = val.slice(0,25) + ".....";
            }
            return val
        },
        // Type(){
        //     return this.$store.getters['user/getNotificationType'].type
        // }
    },
    methods:{
        close(){
            this.$store.commit('user/setNotification',{value:false})
        }
    },
    async mounted(){
        setTimeout(async()=>{
            this.$store.commit('user/setNotification',{value:false})
        },7000)
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #notification-card{
        height:54px;
        width:90vw;
        margin-left:3vw;
        margin-top:3vh;
        padding-left:0;
        margin-bottom:1.5vh;
        display: grid;
        grid-template-columns: 4vw 86vw;
        animation:slideIn;
        animation-duration: 0.75s;
        animation-fill-mode: forwards;
        overflow: hidden;
        }
    .notification-green{
        border-radius: 4px;
border: 1px solid var(--brand-color, #25AD34);
background: var(--White, #FFF);
    }
    #notification-1{
       height: 54px;
       width:4vw;
       border-radius: 4px 0px 0px 4px;
border: 1px solid var(--brand-color, #25AD34);
background: var(--brand-color, #25AD34);
    }
   #notification-2{
    display: grid;
    grid-template-columns:77vw 9vw ;
   }
   #notification-close{
    margin-left:2vw;
    margin-top:1.5vh;
    cursor:pointer
   }
#notification-text{
    padding-left:1vw;
    padding-top:5px;
    padding-bottom:5px;
    padding-right:0.5vw;
    overflow: hidden;
}
#notification-text h2{
    color: var(--brand-color, #25AD34);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
word-wrap: break-word;
}
#notification-text h3{
    color: var(--Brand-color-3, #8D8D8D);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-top:8px;
word-wrap: break-word;
}
@keyframes slideIn{
    from{
        margin-left:-94vw;
    }
    to{
        margin-left:3vw;
    }
}
}
@media only screen and (min-width:481px) and (max-width:960px){
        #notification-card{
        height:60px;
        width:47vw;
        margin-left:4vw;
        margin-top:3vh;
        padding-left:0;
        margin-bottom:2vh;
        display: grid;
        grid-template-columns: 4vw 42vw;
        animation:slideIn;
        animation-duration: 0.75s;
        animation-fill-mode: forwards;
        overflow: hidden;
        }
    .notification-green{
        border-radius: 4px;
border: 1px solid var(--brand-color, #25AD34);
background: var(--White, #FFF);
    }
    #notification-1{
       height: 60px;
       width:4vw;
       border-radius: 4px 0px 0px 4px;
border: 1px solid var(--brand-color, #25AD34);
background: var(--brand-color, #25AD34);
    }
   #notification-2{
    display: grid;
    grid-template-columns:37vw 6vw ;
   }
   #notification-close{
    margin-left:2vw;
    margin-top:1.5vh;
    cursor:pointer
   }
#notification-text{
    padding-left:0.75vw;
    padding-top:5px;
    padding-bottom:5px;
    padding-right:0.5vw;
    overflow: hidden;
}
#notification-text h2{
    color: var(--brand-color, #25AD34);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
word-wrap: break-word;
}
#notification-text h3{
    color: var(--Brand-color-3, #8D8D8D);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-top:8px;
word-wrap: break-word;
}
@keyframes slideIn{
    from{
        margin-left:-50vw;
    }
    to{
        margin-left:4vw;
    }
}
}
@media only screen and (min-width:961px){
    #notification-card{
        height:54px;
        width:30vw;
        margin-left:2vw;
        margin-top:3vh;
        padding-left:0;
        margin-bottom:2vh;
        display: grid;
        grid-template-columns: 4vw 26vw;
        animation:slideIn;
        animation-duration: 0.75s;
        animation-fill-mode: forwards;
        overflow:hidden;
        }
    .notification-green{
border-radius: 4px;
border: 1px solid var(--brand-color, #25AD34);
background: var(--White, #FFF);
    }
    #notification-1{
       height: 54px;
       width:4vw;
       border-radius: 4px 0px 0px 4px;
border: 1px solid var(--brand-color, #25AD34);
background: var(--brand-color, #25AD34);
    }
   #notification-2{
    display: grid;
    grid-template-columns:22vw 4vw ;
   }
   #notification-close{
    margin-left:2vw;
    margin-top:1.5vh;
    cursor:pointer
   }
#notification-text{
    padding-left:0.75vw;
    padding-top:5px;
    padding-bottom:5px;
    padding-right:0.5vw;
    overflow: hidden;

}
#notification-text h2{
    color: var(--brand-color, #25AD34);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
word-wrap: break-word;
}
#notification-text h3{
    color: var(--Brand-color-3, #8D8D8D);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-top:8px;
word-wrap: break-word;
}
@keyframes slideIn{
    from{
        margin-left:-30vw;
    }
    to{
        margin-left:2vw;
    }
}
}
</style>