<template>
    <div>
        <div id="container">
            <head-comp></head-comp>
            <div id="loader" v-if="Loading">
                <loader-comp></loader-comp>
            </div>
            <div id="delivery-details" v-else>
               <div id="row-1">
                <div id="row1-1">
                    <back-button @click="back"></back-button>
                    <h2>{{ Status }}</h2>
                </div>
                <div id="row1-2">
                    <h2 id="row1-2-title">Order ID</h2>
                    <div id="dash"></div>
                    <h2 id="row1-2-value">{{ ID }}</h2>
                </div>
               </div>
                <div id="row-2" v-if="Message==0">
                    <passive-map></passive-map>
                    <div id="driver-updates">
                        <div id="driver-profile">
                            <img :src="Image" :alt="Name" v-if="Image">
                            <img src="https://firebasestorage.googleapis.com/v0/b/eat-express-32d51.appspot.com/o/ASSETS%2FPROFILE-1.png?alt=media&token=4f4850d0-35ed-4abe-a33d-4ce25a074dae" :alt="driverName" v-else>
                            <h2>{{ Name }}</h2>
                        </div>
                        <div id="updates">
                            <div :class="MileStone==8?'cancel':MileStone>=0?'active':'cancel'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-if="Status=='No driver accepted order'">
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-else-if="MileStone==8 && Status=='Order cancelled'">
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                               <div v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" v-if="MileStone>=0">
                                    <path d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0ZM10 2.17285C14.3228 2.17285 17.8259 5.67827 17.8259 10C17.8259 14.3218 14.3228 17.8259 10 17.8259C5.67717 17.8259 2.17407 14.3218 2.17407 10C2.17408 5.67827 5.67717 2.17285 10 2.17285ZM13.8318 5.25147L8.03588 11.0486L6.156 9.1687L4.31152 11.012L6.1914 12.8919L8.04808 14.7485L9.89137 12.9041L15.6885 7.10815L13.8318 5.25147Z" fill="white"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-else>
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                               </div>
                                  <div class="stat">
                                    <h2>Looking for driver</h2>
                                  </div>
                            </div>
                            <div class="divider"></div>
                            <div :class="MileStone==8?'cancel':MileStone>=1?'active':'cancel'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-if="Status=='No driver accepted order'">
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-else-if="MileStone==8 && Status=='Order cancelled'">
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                               <div v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" v-if="MileStone>=1">
                                    <path d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0ZM10 2.17285C14.3228 2.17285 17.8259 5.67827 17.8259 10C17.8259 14.3218 14.3228 17.8259 10 17.8259C5.67717 17.8259 2.17407 14.3218 2.17407 10C2.17408 5.67827 5.67717 2.17285 10 2.17285ZM13.8318 5.25147L8.03588 11.0486L6.156 9.1687L4.31152 11.012L6.1914 12.8919L8.04808 14.7485L9.89137 12.9041L15.6885 7.10815L13.8318 5.25147Z" fill="white"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-else>
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                               </div>
                                
                                  <div class="stat">
                                    <h2>Driver Assigned</h2>
                                  </div>
                            </div>
                            <div class="divider"></div>
                            <div :class="MileStone==8?'cancel':MileStone>=1?'active':'cancel'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-if="Status=='No driver accepted order'">
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-else-if="MileStone==8 && Status=='Order cancelled'">
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                               <div v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" v-if="MileStone>=1">
                                    <path d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0ZM10 2.17285C14.3228 2.17285 17.8259 5.67827 17.8259 10C17.8259 14.3218 14.3228 17.8259 10 17.8259C5.67717 17.8259 2.17407 14.3218 2.17407 10C2.17408 5.67827 5.67717 2.17285 10 2.17285ZM13.8318 5.25147L8.03588 11.0486L6.156 9.1687L4.31152 11.012L6.1914 12.8919L8.04808 14.7485L9.89137 12.9041L15.6885 7.10815L13.8318 5.25147Z" fill="white"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-else>
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                               </div>
                                
                                  <div class="stat">
                                    <h2>Driver Approaching pickup location</h2>
                                  </div>
                            </div>
                            <div class="divider"></div>
                            <div :class="MileStone==8?'cancel':MileStone>=2?'active':'cancel'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-if="Status=='No driver accepted order'">
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-else-if="MileStone==8 && Status=='Order cancelled'">
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                               <div v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" v-if="MileStone>=2">
                                    <path d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0ZM10 2.17285C14.3228 2.17285 17.8259 5.67827 17.8259 10C17.8259 14.3218 14.3228 17.8259 10 17.8259C5.67717 17.8259 2.17407 14.3218 2.17407 10C2.17408 5.67827 5.67717 2.17285 10 2.17285ZM13.8318 5.25147L8.03588 11.0486L6.156 9.1687L4.31152 11.012L6.1914 12.8919L8.04808 14.7485L9.89137 12.9041L15.6885 7.10815L13.8318 5.25147Z" fill="white"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-else>
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                               </div>
                                
                                  <div class="stat">
                                    <h2>Driver Reached Destination</h2>
                                  </div>
                            </div>
                            <div class="divider"></div>
                            <div :class="MileStone==8?'cancel':MileStone>=3?'active':'cancel'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-if="Status=='No driver accepted order'">
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-else-if="MileStone==8 && Status=='Order cancelled'">
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                               <div v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" v-if="MileStone>=3">
                                    <path d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0ZM10 2.17285C14.3228 2.17285 17.8259 5.67827 17.8259 10C17.8259 14.3218 14.3228 17.8259 10 17.8259C5.67717 17.8259 2.17407 14.3218 2.17407 10C2.17408 5.67827 5.67717 2.17285 10 2.17285ZM13.8318 5.25147L8.03588 11.0486L6.156 9.1687L4.31152 11.012L6.1914 12.8919L8.04808 14.7485L9.89137 12.9041L15.6885 7.10815L13.8318 5.25147Z" fill="white"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-else>
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                               </div>
                               
                                  <div class="stat">
                                    <h2>Driver pickup order</h2>
                                  </div>
                            </div>
                            <div class="divider"></div>
                            <div :class="MileStone==8?'cancel':MileStone>=3?'active':'cancel'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-if="Status=='No driver accepted order'">
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-else-if="MileStone==8 && Status=='Order cancelled'">
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                               <div v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" v-if="MileStone>=3">
                                    <path d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0ZM10 2.17285C14.3228 2.17285 17.8259 5.67827 17.8259 10C17.8259 14.3218 14.3228 17.8259 10 17.8259C5.67717 17.8259 2.17407 14.3218 2.17407 10C2.17408 5.67827 5.67717 2.17285 10 2.17285ZM13.8318 5.25147L8.03588 11.0486L6.156 9.1687L4.31152 11.012L6.1914 12.8919L8.04808 14.7485L9.89137 12.9041L15.6885 7.10815L13.8318 5.25147Z" fill="white"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-else>
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                               </div>
                               
                                  <div class="stat">
                                    <h2>Delivery Starts</h2>
                                  </div>
                            </div>
                            <div class="divider"></div>
                            <div :class="MileStone==8?'cancel':MileStone>=4?'active':'cancel'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-if="Status=='No driver accepted order'">
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-else-if="MileStone==8 && Status=='Order cancelled'">
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                               <div v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" v-if="MileStone>=4">
                                    <path d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0ZM10 2.17285C14.3228 2.17285 17.8259 5.67827 17.8259 10C17.8259 14.3218 14.3228 17.8259 10 17.8259C5.67717 17.8259 2.17407 14.3218 2.17407 10C2.17408 5.67827 5.67717 2.17285 10 2.17285ZM13.8318 5.25147L8.03588 11.0486L6.156 9.1687L4.31152 11.012L6.1914 12.8919L8.04808 14.7485L9.89137 12.9041L15.6885 7.10815L13.8318 5.25147Z" fill="white"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" v-else>
                                    <path d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z" fill="white"/>
                                    <path d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z" fill="white"/>
                                  </svg>
                               </div>
                                  <div class="stat">
                                    <h2>Delivery Completed</h2>
                                  </div>
                            </div>
                        </div>
                    </div>
                    <order-details :value="orderDetails" v-if="Modal"></order-details>
                </div>
                <div id="mobile-buttons" v-if="Message==0">
                    <div id="mob-1">
                        <div id="mob-green" @click="callCustomer">Call Customer</div>
                        <div id="mob-purple" @click="callDriver">Call Driver</div>
                    </div>
                </div>
                <div id="desktop-button" v-if="Message==0" @click="toggleModal(true)">Order Details</div>
                <div class="message-comp" v-if="Message==1">
                    <server-crash msg="Something went wrong at our end"></server-crash>
                </div>
                <div class="message-comp" v-if="Message==2">
                    <error-comp msg="Failed to fetch order details"></error-comp>
                </div>
                
            </div>
        </div>
    </div>
</template>


<script>
import HeadComp from '../../components/deliveries/head-comp.vue'
import BackButton from '../../components/general/back-button.vue'
import LoaderComp from '../../components/general/loader-comp.vue'
import ErrorComp from '../../components/messages/error-comp.vue'
import ServerCrash from '../../components/messages/server-crash.vue'
import Cookies from 'js-cookie'
import PassiveMap from '../../components/deliveries/map-passive.vue'
import OrderDetails from '../../components/deliveries/order-details.vue'
export default {
    components:{HeadComp,BackButton,LoaderComp,PassiveMap,ErrorComp,ServerCrash,OrderDetails},
    data() {
    return {
      driverName:'',
      driverImage:'',
      driverNumber:'',
      customerNumber:'',
      message:0,
      cancel:false,
      status:'Order Completed',
      orderDetails:{},
      mileStone:0
    };
  },
    computed:{
        ID(){
            return this.$route.params.id
        },
        Loading(){
            return this.$store.getters['user/getLoading']
        },
        Message(){
            return this.message
        },
        Canceled(){
            return this.cancel
        },
        Name(){
            return this.driverName
        },
        Image(){
            return this.driverImage
        },
        Status(){
            return this.status
        },
        Modal(){
            return this.$store.getters['orders/getPastModal']
        },
        MileStone(){
        return this.mileStone
    }
    },
    methods:{
        back(){
            this.$router.go(-1)
        },
        callCustomer(){
            const phoneNumber = this.customerNumber; // Replace with the actual mobile number
            const telUrl = `tel:${phoneNumber}`;
            window.location.href = telUrl;
        },
        callDriver(){
              if(this.MileStone!=8){
                if(this.driverNumber !=''){
                const phoneNumber = this.driverNumber; // Replace with the actual mobile number
            const telUrl = `tel:${phoneNumber}`;
            window.location.href = telUrl;
            }
            }
        },
        toggleModal(value){
            this.$store.commit('orders/setPastModal',{value:value})
        }
    },
    async mounted(){
        if(!Cookies.get('authenticated')){
            this.$router.push({name:'landing'})
        }
        this.$store.commit('user/setLoading',{value:true})
        let res = await this.$store.dispatch('orders/pastOrderDetail',{value:this.$route.params.id})
        if(res.status==200){
            this.mileStone = res.data.order_milestone_number
            this.orderDetails = res.data
            this.orderDetails['id'] = this.$route.params.id
            this.customerNumber = res.data.customer_phone
            this.driverNumber = res.data.driver.phone
            this.$store.commit('orders/setCustomerCoordinates',{
                value:res.data.dest_coordinates
            })
            this.$store.commit('orders/setOutletCoordinates',{
                lat:res.data.outletDetails.coordinatesX,
                lng:res.data.outletDetails.coordinatesY
            })
            this.driverName=res.data.driver.name || 'Not assigned'
            this.driverImage = res.data.driver.driver_image
            this.$store.commit('user/setLoading',{value:false})
            if(res.data.status == 'Cancelled'){
                this.status='Order cancelled'
                this.cancel = true
            }
            else if(res.data.status == 'Nobody-Accepted'){
                this.status='No driver accepted order'
                this.cancel = true
            }
        }
        else if(res.status==500){
            this.$store.commit('user/setLoading',{value:false})
            this.message=1
        }
        else{
            this.$store.commit('user/setLoading',{value:false})
            this.message=2
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#delivery-details{
    width:100vw;
    margin-top:10px;
    height: auto;
    overflow-x:hidden;
    padding-bottom:7.75vh;
}
#loader{
    margin-top:20vh;
}
.message-comp{
    width:100vw;
    height: auto;
    display: flex;
    margin-top:10vh;
    justify-content: center;
}
@media only screen and (max-width:480px){
    #container{
        width:100vw;
        overflow-x: hidden;
        position: absolute;
        top:0;
        left:0;
        padding-bottom:2vh;
    }
    #row-1{
        width:96vw;
        overflow-x:hidden;
        height: auto;
        padding-left:2vw;
        padding-right:2vw;
        padding-top:2vh;
    }
    #row1-1{
        display: flex;
        align-items: center;
        padding-left:3.5vw;
    }
    #row1-1 h2{
        color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:10px;
    }
    #row1-2{
        margin-top:3.5vh;
        display: flex;
        align-items: center;
        padding-left:3.5vw
    }
    #row1-2-title{
          color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #row1-2-value{
          color: #413F3F;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #dash{
       height:1px;
       width:20vw;
       background: #8D8D8D;
       margin-left:1vw;
       margin-right:1vw;
       margin-top:10px;
    }
#row2{
    margin-top:1.5vh;
    width:100vw;
    height:auto;
    overflow-x:hidden ;
    padding-left:1.5vw;
    padding-right:1.5vw
}
.active-map{
    width:94vw;
    height:40vh;
    border-radius: 10px;
    margin-top:2vh;
    margin-left:3vw;
 }
 #map{
    width:94vw;
    height:40vh;
 }
 #driver-updates{
    width:98vw;
    height:fit-content;
    padding-top:0.75vh;
    padding-bottom:0.75vh;
    padding-left:1vw;
    padding-right:1vw;
    margin-top:1vh;
}
#driver-profile{
    width:90vw;
    height:55px;
    border-radius: 4px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
display: flex;
align-items: center;
margin-left:5vw;
margin-right:5vw;
margin-top:2vh;
}
#driver-profile img{
    width:50px;
    height:50px;
    border-radius: 50px;
}
#driver-profile h2{
    color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:7px;
}
#updates{
    border-radius: 4px;
border: 1px solid rgba(30, 30, 30, 0.20);
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
width:91vw;
margin-left:3vw;
height: 25vh;
padding-top:1.25vh;
padding-bottom:1.25vh;
overflow-x:hidden;
overflow-y:auto !important;
margin-top:2.75vh;
padding-left:1.5vw;
padding-right:1.5vw
}
#updates::-webkit-scrollbar{
    display: none;
}
.active{
    border-radius: 5px;
background: #25AD34;
height:auto;
min-height:41px;
width:84vw;
margin-left:2vw;
padding-top:2px;
padding-bottom:2px;
display: flex;
flex-direction: row;
align-items: center;
gap:8vw;
}
.cancel{
    border-radius: 5px;
    background: rgb(173,19,19);
    height:auto;
    min-height:41px;
    width:84vw;
    margin-left:2vw;
    padding-top:2px;
    padding-bottom:2px;
    display: flex;
flex-direction: row;
align-items: center;
gap:8vw;
}
.active svg,
.cancel svg,
.passive svg{
    margin-left:2vw;
}
.active h2,
.cancel h2{
    
    margin-right:2vw;
    color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 35px;
letter-spacing: 0.2px;
word-wrap: break-word;
}
.passive{
    border-radius: 5px;
background: transparent;
height:fit-content;
min-height:41px;
width:84vw;
margin-left:2vw;
padding-top:2px;
padding-bottom:2px;
flex-direction: row;
align-items: center;
gap:3vw;
}
.passive h2{
    margin-right:2vw;
    color:black;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 35px;
letter-spacing: 0.2px;
word-wrap:break-word
}
.divider{
    height:1px;
    width:90vw;
    background: rgba(0, 0, 0, 0.207);
    margin-top:5px;
    margin-bottom:5px;
}
#mobile-buttons{
    width:100vw;
    margin-top:2.5vh;
}
#mob-1{
    width:100vw;
    display: flex;
    align-items: center;
}
#mob-purple{
    height:51px;
    border-radius: 16px;
background: #5C5C8B;
display: flex;
align-items: center;
justify-content: center;
width:40vw;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
#mob-green{
    height:51px;
    border-radius: 16px;
background: #25AD34;
display: flex;
align-items: center;
justify-content: center;
width:40vw;
margin-left:5vw;
margin-right:10vw;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
#desktop-button{
    display:none;
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #container{
        width:100vw;
        overflow-x: hidden;
        position: absolute;
        top:0;
        left:0;
        padding-bottom:3vh;
    }
    #row-1{
        width:100vw;
        overflow-x:hidden;
        height: auto;
        padding-left:2vw;
        padding-top:2vh;
    }
    #row1-1{
        display: flex;
        align-items: center;
        padding-left:3.5vw;
    }
    #row1-1 h2{
        color: #413F3F;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2{
        margin-top:3.5vh;
        display: flex;
        align-items: center;
        padding-left:2vw
    }
    #row1-2-title{
          color: #413F3F;
font-family: Inter;
font-size: 23px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2-value{
          color: #413F3F;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #dash{
       height:1px;
       width:25vw;
       background: #8D8D8D;
       margin-left:.5vw;
       margin-right:.5vw;
       margin-top:10px;
    }
    #row2{
        margin-top:2vh;
        width:100vw;
        height: auto;
        overflow-x:auto;
        padding-left:2vw;
        padding-right:2vw;
        display: grid;
        grid-template-columns:59vw 35vw;
        gap:2vw;
    }
    .active-map{
        width:90vw;
        height:40vh;
        border-radius: 10px;
        margin-top:2.5vh;
        margin-left:5vw;
     }
     #map{
        width:90vw;
        height:40vh;
     }
     #driver-updates{
        width:98vw;
        height:fit-content;
        padding-top:0.75vh;
        padding-bottom:0.75vh;
        padding-left:1vw;
        padding-right:1vw;
        margin-top:1vh;
    }
    #driver-profile{
        width:50vw;
        height:65px;
        border-radius: 4px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
display: flex;
align-items: center;
margin-left:5vw;
    }
    #driver-profile img{
        width:60px;
        height:60px;
        border-radius: 60px;
    }
    #driver-profile h2{
        color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:8px;
    }
    
    #updates{
        border-radius: 4px;
    border: 1px solid rgba(30, 30, 30, 0.20);
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    width:81vw;
    margin-left:8vw;
    height: 20vh;
    padding-top:1.25vh;
    padding-bottom:1.25vh;
    overflow-x:hidden;
    overflow-y:auto !important;
    margin-top:1.75vh;
    padding-left:1.5vw;
    padding-right:1.5vw
    }
    #updates::-webkit-scrollbar{
        display: none;
    }
    .active{
        border-radius: 5px;
    background: #25AD34;
    height:fit-content;
    min-height:41px;
    width:74vw;
    margin-left:2vw;
    padding-top:2px;
    padding-bottom:2px;
    display: flex;
flex-direction: row;
align-items: center;
gap:8vw;
    }
    .cancel{
        border-radius: 5px;
        background: rgb(173,19,19);
        height:fit-content;
        min-height:41px;
        width:74vw;
        margin-left:2vw;
        padding-top:2px;
        padding-bottom:2px;
        display: flex;
flex-direction: row;
align-items: center;
gap:8vw;
    }
    .active svg,
    .cancel svg,
    .passive svg{
        margin-left:2vw;
    }
    .active h2,
    .cancel h2{
        float:right;
        margin-right:2vw;
        color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.2px;
    word-wrap:break-word;
    }
    .passive{
        border-radius: 5px;
    background: transparent;
    height:fit-content;
    min-height:41px;
    width:74vw;
    margin-left:2vw;
    padding-top:2px;
    padding-bottom:2px;
    display:flex;
    flex-direction: row;
    align-items: center;
    gap:5vw;
    }
    .passive h2{
        margin-right:2vw;
        color:black;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.2px;
    word-wrap:break-word
    }
    .divider{
        height:1px;
        width:90vw;
        background: rgba(0, 0, 0, 0.207);
        margin-top:5px;
        margin-bottom:5px;
    }
    #mobile-buttons{
        width:100vw;
        margin-top:2.5vh;
    }
    #mob-1{
        width:100vw;
        display: flex;
        align-items: center;
    }
    #mob-purple{
        height:51px;
        border-radius: 16px;
    background: #5C5C8B;
    display: flex;
    align-items: center;
    justify-content: center;
    width:30vw;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }
    #mob-green{
        height:51px;
        border-radius: 16px;
    background: #25AD34;
    display: flex;
    align-items: center;
    justify-content: center;
    width:30vw;
    margin-left:15vw;
    margin-right:10vw;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }
    #desktop-button{
        display:none;
    }
}
@media only screen and (min-width:961px){
    #container{
        width:100vw;
        overflow-x: hidden;
        position: absolute;
        top:0;
        left:0;
        padding-bottom:2vh;
    }
    #row-1{
        width:100vw;
        overflow-x:hidden;
        height: auto;
        padding-left:2vw;
        padding-top:2vh;
    }
    #row1-1{
        display: flex;
        align-items: center;
        padding-left:2vw;
    }
    #row1-1 h2{
        color: #413F3F;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2{
        margin-top:3.5vh;
        display: flex;
        align-items: center;
        padding-left:.75vw
    }
    #row1-2-title{
          color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2-value{
          color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #dash{
       height:1px;
       width:40vw;
       background: #8D8D8D;
       margin-left:.5vw;
       margin-right:.5vw;
       margin-top:10px;
    }
    #row-2{
        margin-top:4vh;
        width:100vw;
        height: auto;
        overflow-x:auto;
        padding-left:2vw;
        padding-right:2vw;
        display: grid;
        grid-template-columns:64vw 30vw;
        gap:2vw;
    }
    #delivery-details{
        padding-bottom:3vh;
        overflow-y:auto;
    }
    #delivery-details::-webkit-scrollbar{
        display: none;
    }
    .active-map{
        width:60vw;
        margin-left:2vw;
        height:60vh;
        border-radius: 10px;
     }
     #map{
        width:60vw;
        height:60vh;
     }
     #driver-updates{
        width:28vw;
        height:fit-content;
        padding-top:1vh;
        padding-bottom:1vh;
        padding-left:1vw;
        padding-right:1vw
    }
    #driver-profile{
        width:28vw;
        height:55px;
        border-radius: 4px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
display: flex;
align-items: center;
    }
    #driver-profile img{
        width:52px;
        height:52px;
        border-radius: 52px;
    }
    #driver-profile h2{
        color: #413F3F;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:8px;
    }
    #updates{
        border-radius: 4px;
    border: 1px solid rgba(30, 30, 30, 0.20);
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    width:28vw;
    height: 50vh;
    padding-top:1.25vh;
    padding-bottom:1.25vh;
    overflow-x:hidden;
    overflow-y:auto !important;
    margin-top:1.75vh;
    padding-left:1vw;
    padding-right:1vw
    }
    #updates::-webkit-scrollbar{
        display: none;
    }
    .active{
        border-radius: 5px;
    background: #25AD34;
    height:auto;
    min-height:41px;
    width:26vw;
    padding-top:5px;
    padding-bottom:5px;
    display:flex;
    align-items: center;
    gap:5.5vw;
    }
    .cancel{
        border-radius: 5px;
        background: rgb(173,19,19);
        height:auto;
        min-height:41px;
        width:26vw;
        padding-top:5px;
        padding-bottom:5px;
        align-items: center;
        display:flex;
        align-items: center;
        gap:5.5vw;
    }
    .active svg,
    .cancel svg,
    .passive svg{
        margin-left:1vw;
        margin-top:5px;
    }
    .active h2,
    .cancel h2{
        float:right;
        margin-right:2vw;
        color: #FFF;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    word-wrap:break-word;
    }
    .passive{
        border-radius: 5px;
    background: transparent;
    min-height:41px;
    width:26vw;
    padding-top:2px;
    padding-bottom:2px;
    display:flex;
    flex-direction: row;
    align-items: center;
    }
    .passive h2{
        margin-right:2vw;
        color:black;
    text-align: right;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.2px;
    }
    .divider{
        height:1px;
        width:90vw;
        background: rgba(0, 0, 0, 0.207);
        margin-top:5px;
        margin-bottom:5px;
    }
    #mobile-buttons{
        display: none;
    }
    #desktop-button{
        background: #25AD34;
        width:20vw;
        height:55px;
        margin-left:40vw;
        margin-top:4vh;
        border-radius:16px;
        display:flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color:white;
        font-size:24px;
        font-weight:500;
        font-family:Inter;
    }
}
</style>