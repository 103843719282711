const hostName = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import Cookies from "js-cookie";
import { db } from "../../firebase";
import { getDoc, doc } from "firebase/firestore";
export default {
  async placeOrder(context, payload) {
    try {
      const reqBody = {
        customer_name: payload.customer_name,
        customer_phone: payload.customer_phone,
        address: payload.address,
        mode_of_payment: payload.mode_of_payment,
        bags: 2,
        delivery_instructions: payload.delivery_instructions,
        food_order_value: parseFloat(payload.food_order_value),
      };
      const reqUrl = `${hostName}/outlet/create-order`;
      const headers = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      let res = await axios.post(reqUrl, reqBody, {
        headers: headers,
      });
      if (res.status == 200) {
        // will set order details in cookies
        return { status: 200, data: res.data.orderId };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async deliveryCost(context, payload) {
    try {
      let reqBody = {
        address: payload.value,
      };
      let reqUrl = `${hostName}/outlet/get-pricing`;
      const headers = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      let res = await axios.post(reqUrl, reqBody, {
        headers: headers,
      });
      if (res.status == 200) {
        return { stat: 200, data: res.data.pricing.delivery_price };
      }
    } catch (err) {
      return { stat: err.response.status, data: "" };
    }
  },
  async cancelOrder(context, payload) {
    try {
      let reqBody = {
        orderId: payload.value,
      };
      let headers = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      let reqUrl = `${hostName}/outlet/cancel-order`;
      let res = await axios.post(reqUrl, reqBody, {
        headers: headers,
      });
      return res.status;
    } catch (err) {
      return err.response.status;
    }
  },
  async getOrderStatus(context, payload) {
    try {
      let reqUrl = `${hostName}/outlet/order-status/${payload.value}`;
      const headers = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      let res = await axios.get(reqUrl, {
        headers: headers,
      });
      if (res.status == 200) {
        return { status: 200, data: res.data.orderData };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async editName(context, payload) {
    try {
      let reqBody = {
        orderId: payload.order,
        customerName: payload.name,
      };
      let reqUrl = `${hostName}/outlet/edit-order-customer-name`;
      let headers = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      let res = await axios.put(reqUrl, reqBody, {
        headers: headers,
      });
      return res.status;
    } catch (err) {
      return err.response.status;
    }
  },
  async editContact(context, payload) {
    try {
      let reqBody = {
        orderId: payload.order,
        customerPhone: payload.contact,
      };
      let reqUrl = `${hostName}/outlet/edit-order-customer-phone`;
      let headers = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      let res = await axios.put(reqUrl, reqBody, {
        headers: headers,
      });
      return res.status;
    } catch (err) {
      return err.response.status;
    }
  },
  async previewEditAddress(context, payload) {
    try {
      const reqBody = {
        orderId: payload.order,
        newAddress: payload.address,
        newLandmark: "",
      };
      const reqUrl = `${hostName}/outlet/preview-edit-order-address`;
      const headers = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      let res = await axios.post(reqUrl, reqBody, {
        headers: headers,
      });
      if (res.status == 200) {
        return {
          status: 200,
          data: res.data.data,
        };
      }
    } catch (err) {
      return {
        status: err.response.status,
      };
    }
  },
  async editAddress(context, payload) {
    try {
      let reqBody = {
        orderId: payload.order,
        newAddress: payload.address,
      };
      let headers = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      let reqUrl = `${hostName}/outlet/edit-order-address`;
      let res = await axios.put(reqUrl, reqBody, {
        headers: headers,
      });
      return res.status;
    } catch (err) {
      return err.response.status;
    }
  },
  async getDriverCoordinates(context, payload) {
    try {
      const user_id = payload.value;
      const collection = process.env.VUE_APP_DRIVER_COLLECTION;
      const q = doc(db, collection, user_id);
      let driver = await getDoc(q);
      if (driver.exists()) {
        return driver.data();
      } else {
        return "";
      }
    } catch (err) {
      return "";
    }
  },
  async activeOrders() {
    try {
      let reqUrl = `${hostName}/outlet/delivery-in-progress`;
      const reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      if (res.status == 200) {
        return { status: 200, data: res.data.deliveriesInProgress };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async pastOrders(context, payload) {
    try {
      let reqUrl = `${hostName}/outlet/delivery-old-history/${payload.value}`;
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      if (res.status == 200) {
        return {
          status: 200,
          data: res.data.ordersList,
          pages: res.data.totalPages,
        };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async pastOrderDetail(context, payload) {
    try {
      let reqUrl = `${hostName}/outlet/delivery-old-detail/${payload.value}`;
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      if (res.status == 200) {
        return { status: 200, data: res.data.orderData };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async reassignOrder(context, payload) {
    try {
      let reqUrl = `${hostName}/outlet/reassign-order`;
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let reqBody = { orderId: payload.value };
      const response = await axios.post(reqUrl, reqBody, {
        headers: reqHeaders,
      });
      if (response.status == 200) {
        return { status: 200 };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
};
