<template>
  <div>
    <div id="loader" v-if="Loading">
      <loader-comp></loader-comp>
    </div>
    <div id="live-delivery" v-else>
      <div id="row-1">
        <div id="row1-1">
          <back-button @click="back"></back-button>
          <h2>{{ Status }}</h2>
        </div>
        <div id="row1-2">
          <h2 id="row1-2-title">Order ID</h2>
          <div id="dash"></div>
          <h2 id="row1-2-value">{{ OrderId }}</h2>
        </div>
      </div>
      <div id="row-2" v-if="Message == 0">
        <passive-map v-if="Status == 'Looking for driver'"></passive-map>
        <active-map v-else></active-map>
        <track-driver></track-driver>
        <order-details :value="orderDetails" v-if="Modal"></order-details>
      </div>
      <div id="desktop-buttons" v-if="Message == 0">
        <div id="desk-btn-1" @click="toggleModal(true)">Order Details</div>
        <div id="desk-btn-2" @click="cancel" v-if="CancelState">
          Cancel Trip
        </div>
        <div id="desk-btn-3" v-if="Reassign" @click="reassignOrder">
          Reassign Order
        </div>
      </div>
      <div id="mobile-buttons" v-if="Message == 0">
        <div id="mob-1">
          <div id="mob-green" @click="callCustomer">Call Customer</div>
          <div id="mob-purple" @click="callDriver">Call Driver</div>
        </div>
        <div
          id="mob-gray"
          v-if="Status == 'No Driver Accepted Order'"
          @click="reassignOrder"
        >
          Reassign Order
        </div>
        <div id="mob-gray" @click="cancel" v-if="MobCancel">Cancel Trip</div>
      </div>
      <div class="message-comp" v-if="Message == 1">
        <server-crash msg="Something went wrong at our end"></server-crash>
      </div>
      <div class="message-comp" v-if="Message == 2">
        <error-comp msg="Failed to fetch order details"></error-comp>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "../general/back-button.vue";
import ActiveMap from "./map-active.vue";
import PassiveMap from "./map-passive.vue";
import TrackDriver from "./driver-update.vue";
import LoaderComp from "../general/loader-comp.vue";
import ErrorComp from "../../components/messages/error-comp.vue";
import ServerCrash from "../../components/messages/server-crash.vue";
import OrderDetails from "../../components/deliveries/active-details.vue";
import Cookies from "js-cookie";
export default {
  components: {
    BackButton,
    ActiveMap,
    TrackDriver,
    PassiveMap,
    LoaderComp,
    ErrorComp,
    ServerCrash,
    OrderDetails,
  },
  data() {
    return {
      status: "",
      pollingInterval: null,
      message: 0,
      driverNumber: "",
      customerNumber: "",
      orderDetails: {},
      cancel_state: true,
      reassign: false,
      execute: true,
    };
  },
  computed: {
    Status() {
      let val = this.$store.getters["orders/getTrackIndicator"];
      if (val == 0) {
        return "Looking for driver";
      } else if (val == 1) {
        return "Driver is assigned";
      } else if (val == 2) {
        return "Driver is Approaching";
      } else if (val == 3) {
        return "Driver has arrived";
      } else if (val == 4) {
        return "Package picked up";
      } else if (val == 5) {
        return "Delivery Started";
      } else if (val == 6) {
        return "Package Delivered";
      } else if (val === 7) {
        return "Order Canceled";
      } else if (val == 8) {
        return "No Driver Accepted Order";
      } else {
        return "Canceled";
      }
    },
    OrderId() {
      return this.$route.params.id;
    },
    Loading() {
      return this.$store.getters["user/getLoading"];
    },
    Message() {
      return this.message;
    },
    Modal() {
      return this.$store.getters["orders/getActiveModal"];
    },
    CancelState() {
      return this.cancel_state;
    },
    Reassign() {
      return this.reassign;
    },
    MobCancel() {
      let val = this.$store.getters["orders/getTrackIndicator"];
      return val != 7 && val != 6;
    },
  },
  methods: {
    async updateDriver() {
      if (this.$route.name == "liveOrder" && this.execute == true) {
        this.pollingInterval = setTimeout(async () => {
          let res = await this.$store.dispatch("orders/getOrderStatus", {
            value: this.$route.params.id,
          });
          if (res.status == 200) {
            this.orderDetails = res.data;
            this.$store.commit("orders/setActiveMileStone", {
              value: res.data.order_milestone_number,
            });
            this.orderDetails["id"] = this.$route.params.id;
            this.$store.commit("orders/setDriver", {
              name: res.data.driver.name,
              image: res.data.driver.image,
            });
            this.$store.commit("orders/setCustomerCoordinates", {
              value: res.data.destination_coordinates,
            });
            this.$store.commit("orders/setOutletCoordinates", {
              lat: res.data.outletDetails.coordinatesX,
              lng: res.data.outletDetails.coordinatesY,
            });
            if (res.data.status == "Pending") {
              this.$store.commit("orders/setTrackIndicator", { value: 0 });
            } else if (res.data.status == "Nobody-Accepted") {
              this.$store.commit("orders/setTrackIndicator", { value: 8 });
              this.cancel_state = true;
              this.reassign = true;
              this.execute = false;
            } else if (res.data.status == "Cancelled") {
              this.$store.commit("orders/setTrackIndicator", { value: 7 });
              this.cancel_state = false;
            } else {
              this.reassign = false;
              let coordinates = await this.$store.dispatch(
                "orders/getDriverCoordinates",
                { value: res.data.driver.id }
              );
              if (coordinates != "") {
                this.$store.commit("orders/setDriverCoordinates", {
                  lat: coordinates.coordinatesX,
                  lng: coordinates.coordinatesY,
                });
              }

              if (res.data.status == "Driver-Assigned") {
                this.$store.commit("orders/setTrackIndicator", { value: 1 });
              } else if (res.data.status == "Driver-Approaching") {
                this.$store.commit("orders/setTrackIndicator", { value: 2 });
              } else if (res.data.status == "Driver-Arrived") {
                this.$store.commit("orders/setTrackIndicator", { value: 3 });
              } else if (res.data.status == "Picked-Up") {
                this.$store.commit("orders/setTrackIndicator", { value: 4 });
              } else if (res.data.status == "Driver-Departed") {
                this.$store.commit("orders/setTrackIndicator", { value: 5 });
              } else if (res.data.status == "Delivered") {
                this.$store.commit("orders/setTrackIndicator", { value: 6 });
                this.cancel_state = false;
              } else {
                return;
              }
            }
          } else if (res.status == 500) {
            this.message = 1;
          } else {
            this.message = 2;
          }
          await this.updateDriver();
        }, 5000);
      }
    },
    async reassignOrder() {
      this.$store.commit("user/setLoading", { value: true });
      const res = await this.$store.dispatch("orders/reassignOrder", {
        value: this.$route.params.id,
      });
      this.$store.commit("user/setLoading", { value: false });

      if (res.status == 200) {
        this.execute = false;
        this.$store.commit("orders/setTrackIndicator", { value: 0 });
        this.execute = true;
        setTimeout(async () => {
          await this.updateDriver();
        }, 5500);
      }
    },
    toggleModal(value) {
      this.$store.commit("orders/setActiveModal", { value: value });
    },
    async cancel() {
      this.$store.commit("user/setLoading", { value: true });
      let res = await this.$store.dispatch("orders/cancelOrder", {
        value: this.$route.params.id,
      });
      if (res == 200) {
        this.reassign = false;
        this.cancel_state = false;
        this.$store.commit("orders/setTrackIndicator", { value: 7 });
        this.$store.commit("user/setLoading", { value: false });
      } else if (res == 400) {
        this.$store.commit("user/setLoading", { value: false });
        this.message = 1;
      } else {
        this.$store.commit("user/setLoading", { value: false });
        this.message = 2;
      }
    },

    callCustomer() {
      const phoneNumber = this.customerNumber; // Replace with the actual mobile number
      const telUrl = `tel:${phoneNumber}`;
      window.location.href = telUrl;
    },
    callDriver() {
      if (
        this.$store.getters["orders/getTrackIndicator"] != 0 &&
        this.$store.getters["orders/getTrackIndicator"] != 7 &&
        this.$store.getters["orders/getTrackIndicator"] != 8
      ) {
        if (this.driverNumber != "") {
          const phoneNumber = this.driverNumber; // Replace with the actual mobile number
          const telUrl = `tel:${phoneNumber}`;
          window.location.href = telUrl;
        }
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
  async mounted() {
    if (!Cookies.get("authenticated")) {
      this.$router.push({ name: "landing" });
    }
    this.$store.commit("user/setLoading", { value: true });
    let res = await this.$store.dispatch("orders/getOrderStatus", {
      value: this.$route.params.id,
    });
    if (res.status == 200) {
      this.$store.commit("orders/setActiveMileStone", {
        value: res.data.order_milestone_number,
      });
      this.orderDetails = res.data;
      this.orderDetails["id"] = this.$route.params.id;
      this.customerNumber = res.data.customer_phone;
      this.driverNumber = res.data.driver.phone;
      this.$store.commit("orders/setCustomerCoordinates", {
        value: res.data.destination_coordinates,
      });
      this.$store.commit("orders/setOutletCoordinates", {
        lat: res.data.outletDetails.coordinatesX,
        lng: res.data.outletDetails.coordinatesY,
      });
      if (res.data.status == "Pending") {
        this.$store.commit("orders/setTrackIndicator", { value: 0 });
      } else if (res.data.status == "Nobody-Accepted") {
        this.$store.commit("orders/setTrackIndicator", { value: 8 });
        this.cancel_state = true;
        this.reassign = true;
        this.execute = false;
      } else if (res.data.status == "Cancelled") {
        this.$store.commit("orders/setTrackIndicator", { value: 7 });
        this.cancel_state = false;
      } else {
        let coordinates = await this.$store.dispatch(
          "orders/getDriverCoordinates",
          { value: res.data.driver.id }
        );
        if (coordinates != "") {
          this.$store.commit("orders/setDriverCoordinates", {
            lat: coordinates.coordinatesX,
            lng: coordinates.coordinatesY,
          });
        } else {
          alert("failed to fetch driver coordinates");
        }
        if (res.data.status == "Driver-Assigned") {
          this.$store.commit("orders/setTrackIndicator", { value: 1 });
        } else if (res.data.status == "Driver-Approaching") {
          this.$store.commit("orders/setTrackIndicator", { value: 2 });
        } else if (res.data.status == "Driver-Arrived") {
          this.$store.commit("orders/setTrackIndicator", { value: 3 });
        } else if (res.data.status == "Picked-Up") {
          this.$store.commit("orders/setTrackIndicator", { value: 4 });
        } else if (res.data.status == "Driver-Departed") {
          this.$store.commit("orders/setTrackIndicator", { value: 5 });
        } else if (res.data.status == "Delivered") {
          this.$store.commit("orders/setTrackIndicator", { value: 6 });
          this.cancel_state = false;
        } else {
          return;
        }
      }
      this.$store.commit("user/setLoading", { value: false });
      await this.updateDriver();
    } else if (res.status == 500) {
      this.message = 1;
    } else {
      this.message = 2;
    }
  },
  async beforeUnmount() {
    clearTimeout(this.pollingInterval);
    this.pollingInterval = null;
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

#loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.message-comp {
  width: 100vw;
  height: auto;
  display: flex;
  margin-top: 10vh;
  justify-content: center;
}

@media only screen and (max-width: 480px) {
  #row-1 {
    width: 96vw;
    overflow-x: hidden;
    height: auto;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vh;
  }
  #row1-1 {
    display: flex;
    align-items: center;
    padding-left: 3.5vw;
  }
  #row1-1 h2 {
    color: #413f3f;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-left: 10px;
  }
  #row1-2 {
    margin-top: 3.5vh;
    display: flex;
    align-items: center;
    padding-left: 3.5vw;
  }
  #row1-2-title {
    color: #413f3f;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  #row1-2-value {
    color: #413f3f;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  #dash {
    height: 1px;
    width: 20vw;
    background: #8d8d8d;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-top: 10px;
  }
  #row2 {
    margin-top: 1.5vh;
    width: 100vw;
    /* height: auto; */
    overflow-x: hidden;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
  }
  #desktop-buttons {
    display: none;
  }
  #mobile-buttons {
    width: 100vw;
    margin-top: 2.5vh;
    margin-bottom: 13rem;
  }
  #mob-1 {
    width: 100vw;
    display: flex;
    align-items: center;
  }
  #mob-gray {
    border-radius: 16px;
    border: 1px solid #5c5c8b;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40vw;
    margin-top: 1.5vh;
    margin-left: 30vw;
    color: #5c5c8b;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  #mob-purple {
    height: 51px;
    border-radius: 16px;
    background: #5c5c8b;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40vw;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  #mob-green {
    height: 51px;
    border-radius: 16px;
    background: #25ad34;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40vw;
    margin-left: 5vw;
    margin-right: 10vw;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  #desktop-button {
    display: none;
  }
  #live-delivery {
    width: 100vw;
    margin-top: 10px;
    /* height: auto; */
    overflow-x: hidden;
    padding-bottom: 8.75vh;
  }
}

@media only screen and (min-width: 481px) and (max-width: 960px) {
  #row-1 {
    width: 100vw;
    overflow-x: hidden;
    height: auto;
    padding-left: 2vw;
    padding-top: 2vh;
  }
  #row1-1 {
    display: flex;
    align-items: center;
  }
  #row1-1 h2 {
    color: #413f3f;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-left: 20px;
  }
  #row1-2 {
    margin-top: 2vh;
    display: flex;
    align-items: center;
    padding-left: 0.75vw;
  }
  #row1-2-title {
    color: #413f3f;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-left: 20px;
  }
  #row1-2-value {
    color: #413f3f;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-left: 20px;
  }
  #dash {
    height: 1px;
    width: 25vw;
    background: #8d8d8d;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    margin-top: 10px;
  }
  #row2 {
    margin-top: 2vh;
    width: 100vw;
    height: auto;
    overflow-x: auto;
    padding-left: 2vw;
    padding-right: 2vw;
    display: grid;
    grid-template-columns: 59vw 35vw;
    gap: 2vw;
  }
  #desktop-buttons {
    display: none;
  }
  #mobile-buttons {
    width: 100vw;
    margin-top: 2.5vh;
    margin-bottom: 4rem;
  }
  #mob-1 {
    width: 100vw;
    display: flex;
    align-items: center;
  }
  #mob-gray {
    border-radius: 16px;
    border: 1px solid #5c5c8b;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30vw;
    margin-top: 2vh;
    margin-left: 35vw;
    color: #5c5c8b;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  #mob-purple {
    height: 51px;
    border-radius: 16px;
    background: #5c5c8b;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30vw;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  #mob-green {
    height: 51px;
    border-radius: 16px;
    background: #25ad34;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30vw;
    margin-left: 15vw;
    margin-right: 10vw;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  #desktop-button {
    display: none;
  }
  #live-delivery {
    width: 100vw;
    margin-top: 10px;
    /* height: auto; */
    overflow-x: hidden;
    padding-bottom: 3vh;
  }
}

@media only screen and (min-width: 961px) {
  #row-1 {
    width: 100vw;
    overflow-x: hidden;
    /* height: auto; */
    padding-left: 2vw;
    padding-top: 2vh;
  }
  #row1-1 {
    display: flex;
    align-items: center;
  }
  #row1-1 h2 {
    color: #413f3f;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-left: 20px;
  }
  #row1-2 {
    margin-top: 2vh;
    display: flex;
    align-items: center;
    padding-left: 0.75vw;
  }
  #row1-2-title {
    color: #413f3f;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-left: 20px;
  }
  #row1-2-value {
    color: #413f3f;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-left: 20px;
  }
  #dash {
    height: 1px;
    width: 40vw;
    background: #8d8d8d;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    margin-top: 10px;
  }
  #row-2 {
    margin-top: 4vh;
    width: 100vw;
    /* height: auto; */
    overflow-x: auto;
    padding-left: 2vw;
    padding-right: 2vw;
    display: grid;
    grid-template-columns: 64vw 30vw;
    gap: 2vw;
  }
  #live-delivery {
    padding-bottom: 3vh;
    /* overflow-y: auto; */
  }
  #live-delivery::-webkit-scrollbar {
    display: none;
  }
  #mobile-buttons {
    display: none;
  }
  #desktop-buttons {
    width: 100vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  #desk-btn-1 {
    height: 51px;
    border-radius: 16px;
    background: #25ad34;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20vw;
    margin-left: 40vw;
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  #desk-btn-2 {
    height: 51px;
    border-radius: 16px;
    background: var(--Brand-color-3, #8d8d8d);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20vw;
    margin-left: 40vw;
    margin-top: 1.5vh;
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  #desk-btn-3 {
    height: 51px;
    border-radius: 16px;
    background: #5c5c8b;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20vw;
    margin-left: 40vw;
    margin-top: 1.5vh;
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  #live-delivery {
    width: 100vw;
    margin-top: 10px;
    /* height: auto; */
    overflow-x: hidden;
    padding-bottom: 1.75vh;
  }
}
</style>
