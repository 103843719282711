<template>
 <div>
    <div id="select-address">
        <div class="input-case">
            <input type="text" class="input-1" placeholder="Enter Address" v-model="option" @click="toggle">
        </div>
        <div id="address-options" v-if="ToggleState" :class="ToggleState?'animate':'hide'">
            <div class="address-option" v-for="option,index in Options" :key="index" @click="setOption(option.address)">
                {{ option.address }}
            </div>
        </div>
        
    </div>
    <h2 class="error-message" v-if="Error">Enter a valid address</h2>
 </div>
</template>

<script>
export default {
    data(){
        return{
            option:'',
            options:[],
            expand:false,
            zipCode:'',
            errorField:'',
                errorMessage:""
        }
    },
    watch:{
        async option(value){
          if(value.length>4){
            this.$store.commit('orders/setEditAddress',{value:value})
            let values = await this.$store.dispatch('user/getAddress',{value:value})
            if(values.length==0){
                this.errorField=1;
                this.errorMessage="Please enter a valid ZipCode"
                 this.expand=false
            }
            else{
                this.options=values
                  this.expand=true
                  this.errorField=0
            }
            
          }
          else if(value.length<7){
            this.options=[]
            this.expand=false
            this.errorField=1
            this.errorMessage="Please enter a valid ZipCode"
          }
        }
    },
    methods:{
        async setOption(value){
            this.option = value
            this.$store.commit('orders/setEditAddress',{value:value})
            this.expand=!this.expand
        },
        toggle(){
            this.expand=!this.expand
        }
    },
    computed:{
        ToggleState(){
            return this.expand
        },
        Options(){
            return this.options;
        },
        Error(){
               return this.$store.getters['orders/getEditAddress']  == 'error'            },
    }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
.hide{
    display: none;
}
@media only screen and (max-width:480px){
    #select-address{
        margin-bottom:20px;
        width:90vw;
        margin-left:5vw;
        border-radius: 30px;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
        height: fit-content;
        padding-top:0px;   
        max-height: 200px;
        overflow-x: hidden;
    }
    #select-address::-webkit-scrollbar{
        display:none;
    }
    .input-case{
        width:90vw;
        height:54px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
display:inline-flex;
padding-top:0px;
margin-top:0px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:85vw;
        height:54px;
        border-radius: 30px;
background: #FFF;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-top:0px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
#address-options{
    height:130px;
overflow-y: auto;
overflow-x:hidden;
padding-left:5vw;
padding-top:10px;
padding-bottom:10px;
}
#address-options::-webkit-scrollbar{
    display: none;
}
.address-option{
    color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-top:7px;
margin-bottom:7px;
}
.error-message{
    font-family: Inter;
    font-size:14px;
    color:rgba(255, 0, 0, 0.601);
    font-weight: 400;
    margin-left:6.75vw;
    margin-top:-19px;
    margin-bottom:10px;
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #select-address{
        margin-bottom:21.52px;
        width:60vw;
        margin-left:20vw;
        border-radius: 30px;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
        height: fit-content;
        padding-top:0px;
        max-height: 250px;
        overflow-x: hidden;
        overflow-y:auto
    }
    #select-address::-webkit-scrollbar{
        display: none;
    }
    .input-case{
        width:60vw;
        height:67px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
display:inline-flex;
padding-top:0px;
margin-top:0px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:60vw;
        height:67px;
        border-radius: 30px;
background: #FFF;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-top:0px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
        font-family: Inter;
        font-size: 19px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
    }
    #address-options{
        overflow-y: auto;
        overflow-x: hidden;
        }
        #address-options::-webkit-scrollbar{
            display: none;
        }
        #address-options{
            height:170px;
        overflow-y: auto;
        overflow-x:hidden;
        padding-left:2vw;
        padding-top:10px;
        padding-bottom:10px;
        }
        #address-options::-webkit-scrollbar{
            display: none;
        }
        .address-option{
            color: #8D8D8D;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-top:9px;
        margin-bottom:9px;
        }
        .error-message{
            font-family: Inter;
            font-size:15px;
            color:rgba(255, 0, 0, 0.601);
            font-weight: 400;
            margin-left:20.75vw;
            margin-top:-16px;
            margin-bottom:12px;
        }
}
@media only screen and (min-width:961px){
    #select-address{
        margin-bottom:21.52px;
        width:30vw;
        margin-left:35vw;
        border-radius: 30px;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
        height: fit-content;
        padding-top:0px;
        max-height: 300px;
        overflow-x: hidden;
        overflow-y:auto
    }
    #select-address::-webkit-scrollbar{
        display: none;
    }
    .input-case{
        width:30vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
display:inline-flex;
padding-top:0px;
margin-top:0px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:30vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-top:0px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
        font-family: Inter;
        font-size: 19px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
    }
    #address-options{
        overflow-y: auto;
        overflow-x: hidden;
        }
        #address-options::-webkit-scrollbar{
            display: none;
        }
        #address-options{
            height:100px;
        overflow-y: auto;
        overflow-x:hidden;
        padding-left:2vw;
        padding-top:10px;
        padding-bottom:10px;
        }
        #address-options::-webkit-scrollbar{
            display: none;
        }
        .address-option{
            color: #8D8D8D;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-top:10px;
        cursor: pointer;
        margin-bottom:10px;
        }
    .address-option:hover{
        background: var(--brand-color, #25AD34);
        width:20vw;
        border-radius: 3px;
        color: white;
    }
    .error-message{
        font-family: Inter;
        font-size:14px;
        color:rgba(255, 0, 0, 0.601);
        font-weight: 400;
        margin-left:36.15vw !important;
        margin-top:-16px;
    }
}
.animate{
    animation: expand;
    animation-fill-mode: forwards;
    animation-duration: 0.1s;
}
@keyframes expand{
    from{
        height: 0px;

    }
    to{
        height:fit-content;
    }
}
</style>