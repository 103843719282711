export default {
  setPaymentFieldError(state, payload) {
    state.paymentFieldError = payload.value;
  },
  setTrackOrder(state, payload) {
    state.trackOrder = payload.value;
  },
  setTrackIndicator(state, payload) {
    state.trackIndicator = payload.value;
  },
  setCustomerCoordinates(state, payload) {
    let coordinates = payload.value.split(",");
    const lat = parseFloat(coordinates[0]);
    const lng = parseFloat(coordinates[1]);
    state.customerCoordinates = { lat: lat, lng: lng };
  },
  setOutletCoordinates(state, payload) {
    state.outletCoordinates = {
      lat: parseFloat(payload.lat),
      lng: parseFloat(payload.lng),
    };
  },
  setDriverCoordinates(state, payload) {
    state.driverCoordinates = { lat: payload.lat, lng: payload.lng };
  },
  setDriver(state, payload) {
    state.driver = { name: payload.name, image: payload.image };
  },
  setCurrrOrder(state, payload) {
    state.currOrder = payload.value;
  },
  setModal(state, payload) {
    state.modal = payload.value;
  },
  setNewDetails(state, payload) {
    state.newDetails = payload.value;
  },
  setAddress(state, payload) {
    state.address = payload.value;
  },
  setEditAddress(state, payload) {
    state.edit_address = payload.value;
  },
  setDeliveryCost(state, payload) {
    state.deliveryCost = payload.value;
  },
  setCostCalculated(state, payload) {
    state.costCalculated = payload.value;
  },
  setPastModal(state, payload) {
    state.pastModal = payload.value;
  },
  setActiveModal(state, payload) {
    state.activeModal = payload.value;
  },
  setActiveMileStone(state, payload) {
    state.activeMileStone = payload.value;
  },
};
