<template>
    <div id="container">
        <div id="form">
            <back-button id="back-button" @click="back"></back-button>
            <div id="sign-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="74" height="5" viewBox="0 0 74 5" fill="none">
                    <path d="M73.0111 2.64648L70.7017 0.337083L68.3923 2.64648L70.7017 4.95589L73.0111 2.64648ZM70.7017 2.24648H0.527344V3.04648H70.7017V2.24648Z" fill="#25AD34"/>
                  </svg>
                  <h2>Set New Password</h2>
                  <svg xmlns="http://www.w3.org/2000/svg" width="73" height="5" viewBox="0 0 73 5" fill="none">
                    <path d="M0.333177 2.64648L2.64258 4.95589L4.95198 2.64648L2.64258 0.337083L0.333177 2.64648ZM72.8169 2.24648H2.64258V3.04648H72.8169V2.24648Z" fill="#25AD34"/>
                  </svg>
            </div>
           <div id="form-box" v-if="Message==0">
            <input type="text" :class="Error == 1?'input-error':'input-1'" placeholder="Current Password" v-model="current" ref="input-1" @keydown="moveToNextInput($event, 'input-2')">
            <h2 class="error-message" v-if="Error==1">{{ ErrorMessage }}</h2>
            <input type="password" :class="Error == 2?'input-error':'input-1'" placeholder="New Password" v-model="password" ref="input-2" @keydown="moveToNextInput($event, 'input-3')">
            <h2 class="error-message" v-if="Error==2">{{ ErrorMessage }}</h2>
            <input type="password" :class="Error == 3?'input-error':'input-1'" placeholder="Confirm Password" v-model="confirm" ref="input-3" @keydown="moveToNextInput($event, 'input-4')">
            <h2 class="error-message" v-if="Error==3">{{ ErrorMessage }}</h2>
        </div>
        <div id="loader" v-if="Loading">
            <loader-comp></loader-comp>
       </div>
            <submit-button Text="Change Password" @click="submit" v-if="Message==0"></submit-button>
            <div class="message-comp" v-if="Message==1">
                <success-comp msg="Password changed successfully"></success-comp>
            </div>
            <div class="message-comp" v-if="Message==2">
                <server-crash msg="Something went wrong at our end"></server-crash>
            </div>
            <div class="message-comp" v-if="Message==3">
                <error-comp msg="Failed to set new password"></error-comp>
            </div>
        </div>
    </div>
</template>

<script>
import BackButton from '../../components/general/back-button.vue'
import SubmitButton from '../../components/general/submit-button.vue'
import LoaderComp from '../../components/general/loader-comp.vue'
import ServerCrash from '../../components/messages/server-crash.vue'
import ErrorComp from '../../components/messages/error-comp.vue'
import SuccessComp from '../../components/messages/success-comp.vue'
export default {
    components:{BackButton,SubmitButton,LoaderComp,ServerCrash,ErrorComp,SuccessComp},
    data(){
        return{
            current:'',
            password:'',
            confirm:'',
            errorField:'',
            errorMessage:"",
            message:0
        }
    },
    computed:{
            Error(){
                return this.errorField
            },
            ErrorMessage(){
                return this.errorMessage
            },
            Loading(){
                return this.$store.getters['user/getLoading']
            },
            Message(){
                return this.message;
            }
        },
    methods:{
        back(){
             this.$router.go(-1)
        },
        async submit(){
            const passwordCheck1 = await this.passwordCheck1()
             const passwordCheck2 = await this.passwordCheck2()
             if(this.current == ''){
                this.errorField=1;
                this.errorMessage="This field is required"
                
             }
             else if(this.password == ''){
                this.errorField=2;
                this.errorMessage='This field is required'
                
             }
             else if(this.confirm==''){
                this.errorField=3;
                this.errorMessage='This field is required'
                
             }
             
             else if(passwordCheck1){
                this.errorField=2;
                this.errorMessage="Password should contain minimum 8 characters"
                
             }
             else if(passwordCheck2){
                this.errorField=3;
                this.errorMessage="Password do not match"
                
             }
       else{
        this.$store.commit('user/setLoading',{value:true})
        let status = await this.$store.dispatch('user/changePassword',{
            password:this.password,
            confirm:this.confirm,
            current:this.current
        })
        this.$store.commit('user/setLoading',{value:false})
        if(status==200){
            this.message=1
            setTimeout(()=>{
                this.$router.push({name:'profile'})
            },1500)
        }
        else if(status==500){
            this.message==2
            setTimeout(()=>{
                this.message=0
            },1500)
        }
        else{
            this.message=3
            setTimeout(()=>{
                this.message=0
            },1500)
        }
       }
        },
        async passwordCheck1(){
            return this.password.length<8;
        },
        async passwordCheck2(){
            return this.password != this.confirm
        },
        async moveToNextInput(event, nextInputName) {
      if (event.key === 'Enter') {
        if(nextInputName=='input-4'){
            await this.submit()
        }
        else{
            this.$refs[nextInputName].focus();
        }
      }
              }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#container{
    width:100vw;
    height: 100vh;
    overflow-x:hidden;
    overflow-y:auto ;

}
#container::-webkit-scrollbar{
    display: none;
}
#form{
    width:100vw;
    margin-top:6.5vh;
    background-color: white;
    height:93.5vh;

}
#sign-title{
    align-items: center;
    width:100vw;
    display: inline-flex;
    align-items: center; /* Vertical alignment */
justify-content: center; /* Horizontal alignment */
}
.message-comp{
   width:100vw;
   display: flex;
   justify-content: center;
   margin-top:15vh;    
}
@media only screen and (max-width:480px){
    #container{
        background-image: url('~@/assets/images/mobile-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    #form{
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    #back-button{
        margin-left:15px;
        margin-top:15px;
    }
    #sign-title h2{
        color: #413F3F;
        text-align: center;
        font-family: Inter;
        font-size: 27.99px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-left:10px;
        margin-right:10px;
    }
    #sign-title{
        margin-top:20px;
    }
    #form-box{
        margin-top:125px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:90vw;
        margin-left:5vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:90vw;
        margin-left:5vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
margin-bottom:21.52px;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #alternate{
        margin-top:20px;
        color: #0a0a0a;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #link{
        color: #007BFF;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:1px;
    }
    .error-message{
        font-family: Inter;
        font-size:14px;
        color:rgba(255, 0, 0, 0.601);
        font-weight: 400;
        margin-left:6.75vw;
        margin-top:-19px;
        margin-bottom:10px;
    }
    #loader{
        width:auto;
        margin-top:1.5vh;
        margin-bottom:auto;
        display: flex;
        justify-content: center;
    }
}
@media only screen and (min-width:480px) and (max-width:960px){
    #container{
        background-image: url('~@/assets/images/desktop-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    #form{
        border-radius: 20px 20px 0px 0px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    #back-button{
        margin-left:30px;
        margin-top:20px;
    }
    #sign-title h2{
        color: #413F3F;
        text-align: center;
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-left:20px;
        margin-right:20px;
    }
    #sign-title{
        margin-top:115px;
    }
    #form-box{
        margin-top:40px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:60vw;
        margin-left:20vw;
        height:67px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:60vw;
        margin-left:20vw;
        height:67px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
margin-bottom:21.52px;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #alternate{
        margin-top:20px;
        color: #0a0a0a;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #link{
        color: #007BFF;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:1px;
    }
    .error-message{
        font-family: Inter;
        font-size:15px;
        color:rgba(255, 0, 0, 0.601);
        font-weight: 400;
        margin-left:20.75vw;
        margin-top:-16px;
        margin-bottom:12px;
    }
    #loader{
        width:100vw;
        margin-top:2vh;
        margin-bottom:auto;
        display: flex;
        justify-content: center;

    }
}
@media only screen and (min-width:961px){
    #container{
        background-image: url('~@/assets/images/desktop-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
      #form{
        border-radius: 20px 20px 0px 0px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
      #back-button{
        margin-left:30px;
        margin-top:20px;
        cursor: pointer;
    }
    #sign-title h2{
        color: #413F3F;
text-align: center;
font-family: Inter;
font-size: 23.99px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:30px;
margin-right:30px;
    }
    #sign-title{
        margin-top:5px;
    }
    #form-box{
        margin-top:15vh;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:30vw;
        margin-left:35vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:30vw;
        margin-left:35vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
margin-bottom:18.52px;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
    }
    #alternate{
        margin-top:10px;
        color: #0a0a0a;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #link{
        color: #007BFF;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:1px;
cursor: pointer;
    }
    .error-message{
        font-family: Inter;
        font-size:14px;
        color:rgba(255, 0, 0, 0.601);
        font-weight: 400;
        margin-left:35.75vw;
        margin-top:-16px;
        margin-bottom:12px;
    }
    #loader{
        width:100vw;
        margin-top:2vh;
        margin-bottom:auto;
        display: flex;
        justify-content: center;
    }
}
</style>