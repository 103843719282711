<template>
    <div id="container">
         <div id="form">
            <div id="message">
                <h1 id="heading">Try Again!</h1>
                <h2 id="content">You have mistakenly refreshed the page, please close the browser and restart the stripe onboarding process</h2>
            </div>
         </div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Montserrat:wght@500&display=swap');
#container{
    width:100vw;
    height: 100vh;
    overflow-x:hidden;
    overflow-y:auto ;

}
#container::-webkit-scrollbar{
    display: none;
}
#form{
    width:100vw;
    margin-top:6.5vh;
    background-color: white;
    height:93.5vh;

}
@media only screen and (max-width: 480px){
    #container{
        background-image: url('~@/assets/images/mobile-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    #form{
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    #message{
       padding-top:25vh;
    }
    #heading{
        color: #000;
text-align: center;
font-family: Montserrat;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
    }
    #content{
        color: #242424;
text-align: center;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
letter-spacing: 0.2px;
margin-top:2vh;
margin-bottom:10vh;
    }

}
@media only screen and (min-width:480px) and (max-width:960px){
    #container{
        background-image: url('~@/assets/images/desktop-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    #form{
        border-radius: 20px 20px 0px 0px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    #message{
        padding-top:15vh;
     }
     #heading{
         color: #000;
 text-align: center;
 font-family: Montserrat;
 font-size: 36px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 letter-spacing: 0.2px;
     }
     #content{
         color: #242424;
 text-align: center;
 font-family: Montserrat;
 font-size: 20px;
 font-style: normal;
 font-weight: 500;
 line-height: 30px; /* 142.857% */
 letter-spacing: 0.2px;
 margin-top:2vh;
 margin-bottom:5vh;
 width:50vw;
 margin-left:25vw;
     }
}
@media only screen and (min-width:961px){
    #container{
        background-image: url('~@/assets/images/desktop-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
      #form{
        border-radius: 20px 20px 0px 0px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
      #message{
        padding-top:5vh;
        margin-bottom:30vh;
     }
     #heading{
         color: #000;
 text-align: center;
 font-family: Montserrat;
 font-size: 32px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 letter-spacing: 0.2px;
 margin-bottom:20vh;
     }
     #content{
         color: #242424;
 text-align: center;
 font-family: Montserrat;
 font-size: 18px;
 font-style: normal;
 font-weight: 500;
 line-height: 30px; /* 142.857% */
 letter-spacing: 0.2px;
 margin-top:2vh;
 margin-bottom:5vh;
 width:50vw;
 margin-left:25vw;
     }
}
</style>