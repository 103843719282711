<template>
    <div id="container">
         <div id="form">
            <div id="message">
                <h1 id="heading">Stripe Onboarding</h1>
                <h2 id="content">Thank you for signing up your food outlet with EatExpress. In order to proceed further and enjoy our services please complete stripe onboarding.</h2>
            </div>
           <div v-if="Message==0">
            <div id="card-details" >
                <div id="card-element" ref="cardElement" class="input-large"></div>
            </div>
                <div id="loader" v-if="Loading">
                    <loader-comp></loader-comp>
               </div>
                <submit-button Text="Submit" @click="submit"></submit-button>
           </div>
           <div class="message-comp" v-if="Message==1">
            <server-crash msg="Something went wrong at our end"></server-crash>
           </div>
           <div class="message-comp" v-if="Message==2">
            <error-comp msg="Failed to verify card"></error-comp>
           </div>
           <div class="message-comp" v-if="Message==10">
            <error-comp msg="Please refresh and enter your details again,failed to subscribe card"></error-comp>
           </div>
            
         </div>
    </div>
</template>

<script>
    import SubmitButton from '../../components/general/submit-button.vue'
    import LoaderComp from '../../components/general/loader-comp.vue'
    import ServerCrash from '../../components/messages/server-crash.vue'
    import ErrorComp from '../../components/messages/error-comp.vue'
    import { loadStripe } from '@stripe/stripe-js';
export default {
    components:{SubmitButton,LoaderComp,ServerCrash,ErrorComp},
    data(){
        return{
            stripe: null,
      cardElement: null,
      message:0
        }
    },
    computed:{
        Loading(){
            return this.$store.getters['user/getLoading']
        },
        Message(){
            return this.message;
        }
    },
    methods:{
       async submit(){
        this.$store.commit('user/setLoading',{value:true})
        let res = await this.$store.dispatch('user/addCard')
        this.$store.commit('user/setLoading',{value:false})
        if(res.status==200){
            let token = res.data
            const response =  await this.stripe.confirmCardSetup(token.toString(),{
                payment_method: {
      card: this.cardElement,
      billing_details: {},
    },
            })
            if(response.error){
                this.message=10;
                setTimeout(()=>{
                      this.message=0 
                },2000)
            }
            else{
                this.$router.replace('/thankyou-outlet');
            }

        }
        else if(res.status==500){
            this.message=1;
            setTimeout(()=>{
                this.message=0
            },2000)
        }
        else{
            this.message=2;
            setTimeout(()=>{
                this.message=0
            },2000)
        }
       },
       async setupStripeElements() {
       this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
       const options = {
  mode: 'setup',
  currency: 'gbp',
  // Fully customizable with appearance API.
  appearance: {/*...*/},
};
      const elements = this.stripe.elements(options);
      this.cardElement = elements.create('card');
      this.cardElement.mount(this.$refs.cardElement);
    }
    },
    async mounted() {
    await this.setupStripeElements();
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Montserrat:wght@500&display=swap');
#container{
    width:100vw;
    height: 100vh;
    overflow-x:hidden;
    overflow-y:auto ;

}
#container::-webkit-scrollbar{
    display: none;
}
#form{
    width:100vw;
    margin-top:6.5vh;
    background-color: white;
    height:93.5vh;

}
.message-comp{
    width:100vw;
    display: flex;
    justify-content: center;
    margin-top:5vh;
}
@media only screen and (max-width: 480px){
    #container{
        background-image: url('~@/assets/images/mobile-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    #form{
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    #message{
       padding-top:10vh;
    }
    #heading{
        color: #000;
text-align: center;
font-family: Montserrat;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
    }
    #content{
        color: #242424;
text-align: center;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
letter-spacing: 0.2px;
margin-top:2vh;
width:90vw;
margin-left:5vw;
margin-bottom:10vh;
    }
    #loader{
        width:auto;
        margin-top:1.5vh;
        margin-bottom:auto;
        display: flex;
        justify-content: center;
    }
    .error-message{
        font-size:16px;
        font-weight: 500;
        color:rgba(197, 5, 5, 0.722);
        font-family: Inter;
        margin-bottom:7px;
        margin-left:1.75vw;
        margin-top:-19px;
    }
    .input-large{
        width:90vw;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
padding-right:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
padding-top:1.75vh;
padding-bottom:1.75vh;
margin-left:5vw;
    }
}
@media only screen and (min-width:480px) and (max-width:960px){
    #container{
        background-image: url('~@/assets/images/desktop-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    #form{
        border-radius: 20px 20px 0px 0px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    #message{
        padding-top:10vh;
     }
     #heading{
         color: #000;
 text-align: center;
 font-family: Montserrat;
 font-size: 36px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 letter-spacing: 0.2px;
     }
     #content{
         color: #242424;
 text-align: center;
 font-family: Montserrat;
 font-size: 20px;
 font-style: normal;
 font-weight: 500;
 line-height: 30px; /* 142.857% */
 letter-spacing: 0.2px;
 margin-top:2vh;
 margin-bottom:5vh;
 width:70vw;
 margin-left:15vw;
     }
     #loader{
        width:100vw;
        margin-top:2vh;
        margin-bottom:auto;
        display: flex;
        justify-content: center;

    }
    .error-message{
        font-size:18px;
        font-weight: 500;
        font-family: Inter;
        margin-bottom:5px;
        margin-left:0.75vw;
        color:rgba(197, 5, 5, 0.722);
        margin-top:-16px;
    }
    .input-large{
        width:60vw;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
padding-right:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
padding-top:1.75vh;
padding-bottom:1.75vh;
margin-left:20vw;
    }
}
@media only screen and (min-width:961px){
    #container{
        background-image: url('~@/assets/images/desktop-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
      #form{
        border-radius: 20px 20px 0px 0px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
      #message{
        padding-top:5vh;
        margin-bottom:5vh;
     }
     #heading{
         color: #000;
 text-align: center;
 font-family: Montserrat;
 font-size: 32px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 letter-spacing: 0.2px;
 margin-bottom:20vh;
     }
     #content{
         color: #242424;
 text-align: center;
 font-family: Montserrat;
 font-size: 18px;
 font-style: normal;
 font-weight: 500;
 line-height: 30px; /* 142.857% */
 letter-spacing: 0.2px;
 margin-top:2vh;
 margin-bottom:5vh;
 width:50vw;
 margin-left:25vw;
     }
     #loader{
        width:100vw;
        margin-top:2vh;
        margin-bottom:auto;
        display: flex;
        justify-content: center;
    }
    .error-message
    {
        font-size:17px;
        font-weight: 500;
        color:rgba(197, 5, 5, 0.722);
        font-family: Inter;
        margin-bottom:5px;
        margin-left:0.75vw;
        margin-top:-16px;
    }
    .input-large{
        width:30vw;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
padding-right:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
padding-top:1.75vh;
padding-bottom:1.75vh;
margin-left:35vw;
    }
}
</style>