<template>
    <div>
        <div id="container">
            <head-comp></head-comp>
            <component :is="component"></component>
        </div>
    </div>
</template>

<script>
import HeadComp from '../../components/deliveries/head-comp.vue'
import LiveDelivery from '@/components/deliveries/live-delivery.vue';
import EditDelivery from '@/components/deliveries/edit-dlivery.vue';
import EditName from '../../components/deliveries/edit-name.vue'
import EditPhone from '../../components/deliveries/edit-phone.vue'
import EditAddress from '../../components/deliveries/edit-address.vue'
import Cookies from 'js-cookie'
export default {
    components:{HeadComp,LiveDelivery,EditDelivery,EditName,EditAddress,EditPhone},
    computed:{
        component(){
            let activeComp = this.$store.getters['orders/getTrackOrder']
            if(activeComp==0){
                return 'LiveDelivery'
            }
            else if(activeComp==1){
                return 'EditDelivery'
            }
            else if(activeComp==2){
                return 'EditName'
            }
            else if(activeComp==3){
                return 'EditPhone'
            }
            else if(activeComp==4){
                return 'EditAddress'
            }
            else{
                return 'LiveDelivery'
            }
        },
    },
    async mounted(){
        if(!Cookies.get('authenticated')){
            this.$router.push({name:'landing'})
        }
    }
}
</script>

<style scoped>
@media only screen and (max-width:480px){
    #container{
        width:100vw;
        height:auto;
        overflow-x: hidden;
        position: absolute;
        top:0;
        left:0;
        padding-bottom:3vh;
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    #container{
        width:100vw;
        height:auto;
        overflow-x: hidden;
        position: absolute;
        top:0;
        left:0;
        padding-bottom:3vh;
    }
}
@media only screen and (min-width:961px){
    #container{
        width:100vw;
        height:auto;
        overflow-x: hidden;
        position: absolute;
        top:0;
        left:0;
        padding-bottom:3vh;
    }
}
</style>