import { createRouter, createWebHistory } from "vue-router";
import OnboardingRoutes from "./onboarding.js";
import DeliveryRoutes from "./delivery.js";
import PaymentRoutes from "./payment.js";
import HomePage from "../views/home/landing-page.vue";
import ProfilePage from "../views/home/profile-page.vue";
import NotificationPage from "../views/home/notification-page.vue";
let routes = [
  {
    name: "home",
    path: "/",
    component: HomePage,
  },
  {
    name: "profile",
    path: "/profile",
    component: ProfilePage,
  },
  {
    name: "notification",
    path: "/notification",
    component: NotificationPage,
  },
];
routes = routes.concat(OnboardingRoutes, DeliveryRoutes, PaymentRoutes);
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
