<template>
    <div id="message">
      <img :src="require('../../assets/messages/crashed.png')" alt="" id="msg-image">
      <h2 id="msg-text">{{ msg }}</h2>
    </div>
  </template>
  
  <script>
  export default {
    props:['msg']
  }
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
  @media only screen and (max-width:480px){
    #message{
      border-radius: 43.555px;
    background: transparent;
    width:300px;
    height:250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }
    #msg-image{
      width:120px;
      height:120px;
    }
    #msg-text{
    font-family: Inter;
    font-size:20px;
    font-weight: 600;
    color:rgba(0, 0, 0, 0.667);
    text-align: center;
    }
  }
  @media only screen and (min-width:481px) and (max-width:960px){
    #message{
      border-radius: 43.555px;
    background: transparent;
    width:300px;
    height:250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }
    #msg-image{
      width:150px;
      height:150px;
    }
    #msg-text{
    font-family: Inter;
    font-size:21px;
    font-weight: 600;
    color:rgba(0, 0, 0, 0.667);
    text-align: center;
    }
  }
  @media only screen and (min-width:961px){
    #message{
      border-radius: 43.555px;
    background: transparent;
    width:300px;
    height:250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }
    #msg-image{
      width:150px;
      height:150px;
    }
    #msg-text{
    font-family: Inter;
    font-size:20px;
    font-weight: 600;
    color:rgba(0, 0, 0, 0.667);
    text-align: center;
    }
  }
  </style>