<template>
  <div id="driver-updates">
    <div id="driver-search" v-if="Searching">
      <div id="circle"></div>
      <h2>Looking for driver...</h2>
    </div>
    <div v-else>
      <div id="driver-1">
        <div id="driver-profile">
          <img :src="Driver.image" :alt="Driver.name" v-if="Image" />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/eat-express-32d51.appspot.com/o/ASSETS%2FPROFILE-1.png?alt=media&token=4f4850d0-35ed-4abe-a33d-4ce25a074dae"
            :alt="Driver.name"
            v-else
          />
          <h2>{{ Driver.name }}</h2>
        </div>
        <div id="edit-button" @click="editDetails" v-if="CanEdit">
          Edit Delivery Information
        </div>
      </div>
      <div id="updates">
        <div
          :class="
            Status == 8
              ? 'cancel'
              : Status == 7 && MileStone < 0
              ? 'cancel'
              : MileStone == 8
              ? 'cancel'
              : MileStone >= 0
              ? 'active'
              : 'passive'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            v-if="Status == 8"
          >
            <path
              d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
              fill="white"
            />
            <path
              d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            v-else-if="Status == 7 && MileStone == 8"
          >
            <path
              d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
              fill="white"
            />
            <path
              d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
              fill="white"
            />
          </svg>
          <div v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              v-if="MileStone >= 0"
            >
              <path
                d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0ZM10 2.17285C14.3228 2.17285 17.8259 5.67827 17.8259 10C17.8259 14.3218 14.3228 17.8259 10 17.8259C5.67717 17.8259 2.17407 14.3218 2.17407 10C2.17408 5.67827 5.67717 2.17285 10 2.17285ZM13.8318 5.25147L8.03588 11.0486L6.156 9.1687L4.31152 11.012L6.1914 12.8919L8.04808 14.7485L9.89137 12.9041L15.6885 7.10815L13.8318 5.25147Z"
                fill="white"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              v-else
            >
              <path
                d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
                fill="white"
              />
              <path
                d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
                fill="white"
              />
            </svg>
          </div>

          <div class="stat">
            <h2>Looking for driver</h2>
          </div>
        </div>
        <div class="divider"></div>
        <div
          :class="
            Status == 8
              ? 'cancel'
              : Status == 7 && MileStone < 1
              ? 'cancel'
              : MileStone == 8
              ? 'cancel'
              : MileStone >= 1
              ? 'active'
              : 'passive'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            v-if="Status == 8"
          >
            <path
              d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
              fill="white"
            />
            <path
              d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            v-else-if="Status == 7 && MileStone == 8"
          >
            <path
              d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
              fill="white"
            />
            <path
              d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
              fill="white"
            />
          </svg>
          <div v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              v-if="MileStone >= 1"
            >
              <path
                d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0ZM10 2.17285C14.3228 2.17285 17.8259 5.67827 17.8259 10C17.8259 14.3218 14.3228 17.8259 10 17.8259C5.67717 17.8259 2.17407 14.3218 2.17407 10C2.17408 5.67827 5.67717 2.17285 10 2.17285ZM13.8318 5.25147L8.03588 11.0486L6.156 9.1687L4.31152 11.012L6.1914 12.8919L8.04808 14.7485L9.89137 12.9041L15.6885 7.10815L13.8318 5.25147Z"
                fill="white"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              v-else
            >
              <path
                d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
                fill="white"
              />
              <path
                d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="stat">
            <h2>Driver assigned</h2>
          </div>
        </div>
        <div class="divider"></div>
        <div
          :class="
            Status == 8
              ? 'cancel'
              : Status == 7 && MileStone < 1
              ? 'cancel'
              : MileStone == 8
              ? 'cancel'
              : MileStone >= 1
              ? 'active'
              : 'passive'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            v-if="Status == 8"
          >
            <path
              d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
              fill="white"
            />
            <path
              d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            v-else-if="Status == 7 && MileStone == 8"
          >
            <path
              d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
              fill="white"
            />
            <path
              d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
              fill="white"
            />
          </svg>
          <div v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              v-if="MileStone >= 1"
            >
              <path
                d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0ZM10 2.17285C14.3228 2.17285 17.8259 5.67827 17.8259 10C17.8259 14.3218 14.3228 17.8259 10 17.8259C5.67717 17.8259 2.17407 14.3218 2.17407 10C2.17408 5.67827 5.67717 2.17285 10 2.17285ZM13.8318 5.25147L8.03588 11.0486L6.156 9.1687L4.31152 11.012L6.1914 12.8919L8.04808 14.7485L9.89137 12.9041L15.6885 7.10815L13.8318 5.25147Z"
                fill="white"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              v-else
            >
              <path
                d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
                fill="white"
              />
              <path
                d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="stat"><h2>Driver approaching pickup location</h2></div>
        </div>
        <div class="divider"></div>
        <div
          :class="
            Status == 8
              ? 'cancel'
              : Status == 7 && MileStone < 2
              ? 'cancel'
              : MileStone == 8
              ? 'cancel'
              : MileStone >= 2
              ? 'active'
              : 'passive'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            v-if="Status == 8"
          >
            <path
              d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
              fill="white"
            />
            <path
              d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            v-else-if="Status == 7 && MileStone == 8"
          >
            <path
              d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
              fill="white"
            />
            <path
              d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
              fill="white"
            />
          </svg>
          <div v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              v-if="MileStone >= 2"
            >
              <path
                d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0ZM10 2.17285C14.3228 2.17285 17.8259 5.67827 17.8259 10C17.8259 14.3218 14.3228 17.8259 10 17.8259C5.67717 17.8259 2.17407 14.3218 2.17407 10C2.17408 5.67827 5.67717 2.17285 10 2.17285ZM13.8318 5.25147L8.03588 11.0486L6.156 9.1687L4.31152 11.012L6.1914 12.8919L8.04808 14.7485L9.89137 12.9041L15.6885 7.10815L13.8318 5.25147Z"
                fill="white"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              v-else
            >
              <path
                d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
                fill="white"
              />
              <path
                d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="stat"><h2>Driver reached destination</h2></div>
        </div>
        <div class="divider"></div>
        <div
          :class="
            Status == 8
              ? 'cancel'
              : Status == 7 && MileStone < 3
              ? 'cancel'
              : MileStone == 8
              ? 'cancel'
              : MileStone >= 3
              ? 'active'
              : 'passive'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            v-if="Status == 8"
          >
            <path
              d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
              fill="white"
            />
            <path
              d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            v-else-if="Status == 7 && MileStone == 8"
          >
            <path
              d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
              fill="white"
            />
            <path
              d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
              fill="white"
            />
          </svg>
          <div v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              v-if="MileStone >= 3"
            >
              <path
                d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0ZM10 2.17285C14.3228 2.17285 17.8259 5.67827 17.8259 10C17.8259 14.3218 14.3228 17.8259 10 17.8259C5.67717 17.8259 2.17407 14.3218 2.17407 10C2.17408 5.67827 5.67717 2.17285 10 2.17285ZM13.8318 5.25147L8.03588 11.0486L6.156 9.1687L4.31152 11.012L6.1914 12.8919L8.04808 14.7485L9.89137 12.9041L15.6885 7.10815L13.8318 5.25147Z"
                fill="white"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              v-else
            >
              <path
                d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
                fill="white"
              />
              <path
                d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="stat"><h2>Driver picks up order</h2></div>
        </div>
        <div class="divider"></div>
        <div
          :class="
            Status == 8
              ? 'cancel'
              : Status == 7 && MileStone < 3
              ? 'cancel'
              : MileStone == 8
              ? 'cancel'
              : MileStone >= 3
              ? 'active'
              : 'passive'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            v-if="Status == 8"
          >
            <path
              d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
              fill="white"
            />
            <path
              d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            v-else-if="Status == 7 && MileStone == 8"
          >
            <path
              d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
              fill="white"
            />
            <path
              d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
              fill="white"
            />
          </svg>
          <div v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              v-if="MileStone >= 3"
            >
              <path
                d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0ZM10 2.17285C14.3228 2.17285 17.8259 5.67827 17.8259 10C17.8259 14.3218 14.3228 17.8259 10 17.8259C5.67717 17.8259 2.17407 14.3218 2.17407 10C2.17408 5.67827 5.67717 2.17285 10 2.17285ZM13.8318 5.25147L8.03588 11.0486L6.156 9.1687L4.31152 11.012L6.1914 12.8919L8.04808 14.7485L9.89137 12.9041L15.6885 7.10815L13.8318 5.25147Z"
                fill="white"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              v-else
            >
              <path
                d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
                fill="white"
              />
              <path
                d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="stat"><h2>Delivery Starts</h2></div>
        </div>
        <div class="divider"></div>
        <div
          :class="
            Status == 8
              ? 'cancel'
              : Status == 7 && MileStone < 4
              ? 'cancel'
              : MileStone == 8
              ? 'cancel'
              : MileStone >= 4
              ? 'active'
              : 'passive'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            v-if="Status == 8"
          >
            <path
              d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
              fill="white"
            />
            <path
              d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            v-else-if="Status == 7 && MileStone == 8"
          >
            <path
              d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
              fill="white"
            />
            <path
              d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
              fill="white"
            />
          </svg>
          <div v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              v-if="MileStone >= 4"
            >
              <path
                d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0ZM10 2.17285C14.3228 2.17285 17.8259 5.67827 17.8259 10C17.8259 14.3218 14.3228 17.8259 10 17.8259C5.67717 17.8259 2.17407 14.3218 2.17407 10C2.17408 5.67827 5.67717 2.17285 10 2.17285ZM13.8318 5.25147L8.03588 11.0486L6.156 9.1687L4.31152 11.012L6.1914 12.8919L8.04808 14.7485L9.89137 12.9041L15.6885 7.10815L13.8318 5.25147Z"
                fill="white"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              v-else
            >
              <path
                d="M9 0C3.98571 0 0 3.98571 0 9C0 14.0143 3.98571 18 9 18C14.0143 18 18 14.0143 18 9C18 3.98571 14.0143 0 9 0ZM9 16.7143C4.75714 16.7143 1.28571 13.2429 1.28571 9C1.28571 4.75714 4.75714 1.28571 9 1.28571C13.2429 1.28571 16.7143 4.75714 16.7143 9C16.7143 13.2429 13.2429 16.7143 9 16.7143Z"
                fill="white"
              />
              <path
                d="M12.7525 13.8819L8.94093 10.0703L5.12936 13.8819L4 12.7525L7.81158 8.94093L4 5.12936L5.12936 4L8.94093 7.81158L12.7525 4L13.8819 5.12936L10.0703 8.94093L13.8819 12.7525L12.7525 13.8819Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="stat"><h2>Delivery Completed</h2></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  computed: {
    Searching() {
      let val = this.$store.getters["orders/getTrackIndicator"];
      if (val == 0) return true;
      else return false;
    },
    Status() {
      return this.$store.getters["orders/getTrackIndicator"];
    },
    Driver() {
      return this.$store.getters["orders/getDriver"];
    },
    Image() {
      return this.Driver.image;
    },
    MileStone() {
      return this.$store.getters["orders/getActiveMileStone"];
    },
    CanEdit() {
      let val = this.$store.getters["orders/getTrackIndicator"];
      return val != 7 && val != 8 && val != 6;
    },
  },
  methods: {
    async editDetails() {
      this.$store.commit("orders/setTrackOrder", { value: 1 });
    },
  },
  async mounted() {
    if (!Cookies.get("authenticated")) {
      this.$router.push({ name: "landing" });
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@media only screen and (max-width: 480px) {
  #driver-updates {
    width: 98vw;
    height: fit-content;
    padding-top: 0.75vh;
    padding-bottom: 0.75vh;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-top: 1vh;
  }
  #driver-search {
    width: 98vw;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    height: 60px;
    display: flex;
    align-content: center;
    padding-left: 20vw;
  }
  #circle {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: var(--Button-color-2, #5c5c8b);
    animation: animate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  #driver-search h2 {
    color: #413f3f;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 0.2px;
    margin-left: 8px;
  }
  @keyframes animate {
    from {
      background: var(--Button-color-2, #5c5c8b);
    }
    to {
      background: #25ad34;
    }
  }
  #driver-1 {
    width: 96vw;
    display: grid;
    grid-template-columns: 47vw 47vw;
    gap: 2vw;
    padding-left: 1vw;
  }
  #driver-profile {
    width: 45vw;
    height: 55px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #driver-profile img {
    width: 45px;
    height: 45px;
    border-radius: 45px;
  }
  #driver-profile h2 {
    color: #413f3f;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-left: 4px;
  }
  #edit-button {
    background: #8d8d8d;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50vw;
    height: 55px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.2px;
    cursor: pointer;
  }
  #updates {
    border-radius: 4px;
    border: 1px solid rgba(30, 30, 30, 0.2);
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    width: 80vw;
    margin: auto;
    /* margin-left: 3vw; */
    height: 25vh;
    font-size: 14px;
    padding-top: 1.25vh;
    padding-bottom: 1.25vh;
    overflow-x: hidden;
    overflow-y: auto !important;
    margin-top: 2.75vh;
    margin-top: 2.45vh;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
  }
  #updates::-webkit-scrollbar {
    display: none;
  }
  .active {
    border-radius: 5px;
    background: #25ad34;
    height: auto;
    min-height: 41px;
    width: 84vw;
    margin-left: 2vw;
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8vw;
  }
  .cancel {
    border-radius: 5px;
    background: rgb(173, 19, 19);
    height: auto;
    min-height: 41px;
    width: 84vw;
    margin-left: 2vw;
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8vw;
  }
  .active svg,
  .cancel svg,
  .passive svg {
    margin-left: 2vw;
  }
  .active h2,
  .cancel h2 {
    margin-right: 2vw;
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.2px;
    word-wrap: break-word;
  }
  .passive {
    border-radius: 5px;
    background: transparent;
    height: fit-content;
    min-height: 41px;
    width: 84vw;
    margin-left: 2vw;
    padding-top: 2px;
    padding-bottom: 2px;
    flex-direction: row;
    align-items: center;
    gap: 3vw;
  }
  .passive h2 {
    margin-right: 2vw;
    color: black;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.2px;
    word-wrap: break-word;
  }
  .divider {
    height: 1px;
    width: 90vw;
    background: rgba(0, 0, 0, 0.207);
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  #driver-updates {
    width: 98vw;
    height: fit-content;
    padding-top: 0.75vh;
    padding-bottom: 0.75vh;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-top: 1vh;
  }
  #driver-search {
    width: 60vw;
    margin-left: 24vw;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    height: 70px;
    display: flex;
    align-content: center;
  }
  #circle {
    width: 55px;
    height: 55px;
    border-radius: 55px;
    background: var(--Button-color-2, #5c5c8b);
    animation: animate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  #driver-search h2 {
    color: #413f3f;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 0.2px;
    margin-left: 8px;
  }
  @keyframes animate {
    from {
      background: var(--Button-color-2, #5c5c8b);
    }
    to {
      background: #25ad34;
    }
  }
  #driver-1 {
    width: 96vw;
    display: grid;
    grid-template-columns: 25vw 25vw;
    gap: 2vw;
    padding-left: 22vw;
  }
  #driver-profile {
    width: 25vw;
    height: 65px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #driver-profile img {
    width: 60px;
    height: 60px;
    border-radius: 60px;
  }
  #driver-profile h2 {
    color: #413f3f;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-left: 4px;
  }
  #edit-button {
    background: #8d8d8d;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25vw;
    height: 55px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.2px;
    cursor: pointer;
  }
  #updates {
    border-radius: 4px;
    border: 1px solid rgba(30, 30, 30, 0.2);
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    width: 81vw;
    margin-left: 8vw;
    height: 20vh;
    padding-top: 1.25vh;
    padding-bottom: 1.25vh;
    overflow-x: hidden;
    overflow-y: auto !important;
    margin-top: 1.75vh;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
  }
  #updates::-webkit-scrollbar {
    display: none;
  }
  .active {
    border-radius: 5px;
    background: #25ad34;
    height: auto;
    min-height: 41px;
    width: 74vw;
    margin-left: 2vw;
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8vw;
  }
  .cancel {
    border-radius: 5px;
    background: rgb(205, 39, 39);
    height: auto;
    min-height: 41px;
    width: 74vw;
    margin-left: 2vw;
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8vw;
  }
  .active svg,
  .passive svg,
  .cancel svg {
    margin-left: 2vw;
  }
  .active h2,
  .cancel h2 {
    float: right;
    margin-right: 2vw;
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.2px;
    word-wrap: break-word;
  }
  .passive {
    border-radius: 5px;
    background: transparent;
    height: auto;
    min-height: 41px;
    width: 74vw;
    margin-left: 2vw;
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8vw;
  }
  .passive h2 {
    margin-right: 2vw;
    color: black;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.2px;
    word-wrap: break-word;
  }
  .divider {
    height: 1px;
    width: 90vw;
    background: rgba(0, 0, 0, 0.207);
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 961px) {
  #driver-search {
    width: 28vw;
    margin-left: 1vw;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    height: 70px;
    display: flex;
    align-content: center;
    padding-left: 1vw;
  }
  #circle {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background: var(--Button-color-2, #5c5c8b);
    animation: animate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  #driver-search h2 {
    color: #413f3f;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 0.2px;
    margin-left: 8px;
  }
  @keyframes animate {
    from {
      background: var(--Button-color-2, #5c5c8b);
    }
    to {
      background: #25ad34;
    }
  }
  #driver-updates {
    width: 28vw;
    height: fit-content;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;
  }
  #driver-1 {
    width: 28vw;
    display: grid;
    grid-template-columns: 13vw 13vw;
    gap: 2vw;
  }
  #driver-profile {
    width: 13vw;
    height: 55px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #driver-profile img {
    width: 52px;
    height: 52px;
    border-radius: 52px;
  }
  #driver-profile h2 {
    color: #413f3f;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-left: 4px;
  }
  #edit-button {
    background: #8d8d8d;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13vw;
    height: 55px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.2px;
    cursor: pointer;
  }
  #updates {
    border-radius: 4px;
    border: 1px solid rgba(30, 30, 30, 0.2);
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    width: 28vw;
    height: 50vh;
    padding-top: 1.25vh;
    padding-bottom: 1.25vh;
    overflow-x: hidden;
    overflow-y: auto !important;
    margin-top: 1.75vh;
    padding-left: 1vw;
    padding-right: 1vw;
  }
  #updates::-webkit-scrollbar {
    display: none;
  }
  .active {
    border-radius: 5px;
    background: #25ad34;
    min-height: 41px;
    width: 26vw;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    display: flex;
    align-items: center;
    gap: 5.5vw;
  }
  .cancel {
    border-radius: 5px;
    background: rgb(205, 39, 39);
    height: auto;
    min-height: 41px;
    width: 26vw;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    display: flex;
    align-items: center;
    gap: 5.5vw;
  }
  .active svg,
  .passive svg,
  .cancel svg {
    margin-left: 2vw;
  }
  .active h2,
  .cancel h2 {
    margin-right: 2vw;
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    word-wrap: break-word;
  }
  .passive {
    border-radius: 5px;
    background: transparent;
    min-height: 41px;
    width: 26vw;
    padding-top: 2px;
    padding-bottom: 2px;
    align-items: center;
    display: flex;
    align-items: center;
    gap: 5.5vw;
  }
  .passive h2 {
    margin-right: 2vw;
    color: black;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.2px;
    word-wrap: break-word;
  }
  .divider {
    height: 1px;
    width: 90vw;
    background: rgba(0, 0, 0, 0.207);
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>
