<template>
  <div id="container">
    <head-comp></head-comp>
    <div id="back">
      <back-button @click="back"></back-button>
      <h2>Profile</h2>
    </div>
    <div class="divider"></div>
    <div id="loader" v-if="Loading">
      <loader-comp></loader-comp>
    </div>
    <div v-else>
      <div v-if="Message == 0">
        <input
          type="file"
          style="display: none"
          ref="fileInput"
          @change="handleFileChange"
        />
        <img
          :src="ProfileImage"
          :alt="details.restaurant_name"
          id="profile-image"
          v-if="ProfileImage"
          @click="uploadImage"
          :key="ProfileImage"
        />
        <img
          src="https://firebasestorage.googleapis.com/v0/b/eat-express-32d51.appspot.com/o/ASSETS%2FPROFILE-1.png?alt=media&token=4f4850d0-35ed-4abe-a33d-4ce25a074dae"
          alt="Profile Image"
          id="profile-image"
          v-else
        />
        <h2 class="header">{{ details.restaurant_name }}</h2>
        <div class="details">
          <div class="entry">
            <h2 class="key">Contact Number:</h2>
            <h2 class="value">{{ details.phone_no }}</h2>
          </div>
          <div class="entry">
            <h2 class="key">Email ID:</h2>
            <h2 class="value">{{ details.email }}</h2>
          </div>
          <!-- <div class="entry">
                    <h2 class="key">Post Code:</h2>
                    <h2 class="value">{{ details.zip_code }}</h2>
                </div> -->
          <div class="entry">
            <h2 class="key">Location:</h2>
            <h2 class="value">{{ details.address }}</h2>
          </div>
        </div>
        <h2 class="header" v-if="CardDetails.brand">Card Details</h2>
        <div class="details">
          <div class="entry">
            <h2 class="key">Card Number:</h2>
            <h2 class="value">
              {{ CardDetails.brand }} ............{{ CardDetails.number }}
            </h2>
          </div>
          <div class="entry">
            <h2 class="key">Expiration Date:</h2>
            <h2 class="value">
              {{ CardDetails.month }}/{{ CardDetails.year }}
            </h2>
          </div>
        </div>
        <div class="buttons">
          <div class="button" @click="redirect">Change Password</div>
          <div class="button" @click="logout">Log Out</div>
        </div>
      </div>
      <div v-if="Message == 1" class="message-comp">
        <server-crash msg="Something went wrong at our end"></server-crash>
      </div>
      <div v-if="Message == 2" class="message-comp">
        <error-message msg="Failed to fetch outlet profile"></error-message>
      </div>
      <div v-if="Message == 10" class="message-comp">
        <error-message msg="Failed to change profile image"></error-message>
      </div>
      <div v-if="Message == 11" class="message-comp">
        <server-crash msg="Something went wrong at our end"></server-crash>
      </div>
    </div>
  </div>
</template>

<script>
import HeadComp from "../../components/deliveries/head-comp.vue";
import BackButton from "../../components/general/back-button.vue";
import LoaderComp from "../../components/general/loader-comp.vue";
import ServerCrash from "../../components/messages/server-crash.vue";
import ErrorMessage from "../../components/messages/error-comp.vue";
import Cookies from "js-cookie";
export default {
  components: { HeadComp, BackButton, LoaderComp, ServerCrash, ErrorMessage },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async logout() {
      await this.$store.dispatch("user/deleteFcm");
      const allCookies = Cookies.get();
      for (const cookieName in allCookies) {
        Cookies.remove(cookieName);
      }
      this.$router.push({ name: "landing" });
    },
    redirect() {
      this.$router.push({ name: "changePassword" });
    },
    async uploadImage() {
      this.$refs.fileInput.click();
    },
    async handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      this.$store.commit("user/setLoading", { value: true });
      let res = await this.$store.dispatch("user/uploadImage", {
        value: this.selectedFile,
      });
      this.$store.commit("user/setLoading", { value: false });
      if (res.status == 200) {
        this.profile = res.data;
        location.reload();
      } else if (res.status == 500) {
        this.message = 11;
        setTimeout(() => {
          this.message = 0;
        }, 2000);
      } else {
        this.message = 10;
        setTimeout(() => {
          this.message = 0;
        }, 2000);
      }
    },
  },
  data() {
    return {
      details: {},
      message: 0,
      selectedFile: null,
      profile: "",
      card: {},
    };
  },
  computed: {
    ProfileImage() {
      return `${this.profile}?random=${Math.random()}`; //return this.details.profile_image
    },
    Loading() {
      return this.$store.getters["user/getLoading"];
    },
    Message() {
      return this.message;
    },
    CardDetails() {
      return this.card;
    },
  },
  async mounted() {
    if (!Cookies.get("authenticated")) {
      this.$router.push({ name: "landing" });
    }

    this.$store.commit("user/setLoading", { value: true });
    let res = await this.$store.dispatch("user/getProfile");
    this.$store.commit("user/setLoading", { value: false });
    if (res.status == 200) {
      this.details = res.data;
      this.card["number"] = res.cardNumber;
      this.card["brand"] = res.cardBrand;
      this.card["month"] = res.expiryMonth;
      this.card["year"] = res.expiryYear;
      if (!this.details.profile_image) {
        this.profile =
          "https://firebasestorage.googleapis.com/v0/b/eat-express-32d51.appspot.com/o/ASSETS%2FPROFILE-1.png?alt=media&token=4f4850d0-35ed-4abe-a33d-4ce25a074dae";
      } else this.profile = this.details.profile_image;
    } else {
      this.$router.push({ name: "home" });
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.message-comp {
  width: 100vw;
  margin-top: 40vh;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 480px) {
  #container {
    width: 100vw;
    overflow-x: hidden;
    padding-bottom: 7vh;
  }
  .divider {
    display: none;
  }
  #back {
    margin-left: 3vw;
    margin-top: 2vh;
    display: flex;
    align-items: center;
  }
  #back h2 {
    margin-left: 10px;
    color: #333;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  #profile-image {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    margin-left: 3vw;
    margin-top: 3.5vh;
  }
  .header {
    color: var(--Brand-color-2, #413f3f);
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.145px;
    margin-left: 4vw;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .details {
    width: 90vw;
    overflow: hidden;
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .entry {
    display: grid;
    grid-template-columns: 40vw 45vw;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .key {
    color: #413f3f;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.286px;
  }
  .value {
    color: #413f3f;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.286px;
    word-break: break-all;
  }
  .buttons {
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-top: 1.5vh;
    margin-bottom: 7rem;
  }
  .button {
    margin-top: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10.37px;
    background: var(--Brand-color-3, #8d8d8d);
    width: 40vw;
    height: auto;
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 2vw;
    margin-right: 2vw;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
  }
  #loader {
    width: 100vw;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  #container {
    width: 100vw;
    overflow-x: hidden;
    padding-bottom: 2vh;
  }
  .divider {
    display: none;
  }
  #back {
    margin-left: 3.5vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
    display: flex;
    align-items: center;
  }
  #back h2 {
    margin-left: 10px;
    color: #333;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  #profile-image {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    margin-left: 3vw;
    margin-top: 3vh;
  }
  .header {
    color: var(--Brand-color-2, #413f3f);
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.145px;
    margin-left: 4vw;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .details {
    width: 90vw;
    overflow: hidden;
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .entry {
    display: grid;
    grid-template-columns: 25vw 40vw;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .key {
    color: #413f3f;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.286px;
  }
  .value {
    color: #413f3f;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.286px;
  }
  .button {
    margin-top: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10.37px;
    background: var(--Brand-color-3, #8d8d8d);
    width: 40vw;
    height: 51px;
    cursor: pointer;
    margin-left: 30vw;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  #loader {
    width: 100vw;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 961px) {
  #container {
    width: 100vw;
    overflow-x: hidden;
    padding-bottom: 2vh;
  }
  .divider {
    height: 1px;
    background: rgba(80, 78, 78, 0.375);
    width: 96vw;
    margin-left: 2vw;
    margin-top: 10px;
    margin-bottom: 2vh;
  }
  #back {
    margin-left: 2vw;
    margin-top: 2vh;
    display: flex;
    align-items: center;
  }
  #back h2 {
    margin-left: 10px;
    color: #333;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  #profile-image {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-left: 3vw;
    margin-top: 1vh;
    cursor: pointer;
  }
  .header {
    color: var(--Brand-color-2, #413f3f);
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.286px;
    margin-left: 3vw;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .details {
    padding-left: 4vw;
  }
  .entry {
    display: grid;
    grid-template-columns: 12vw 18vw;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .key {
    color: #413f3f;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.286px;
  }
  .value {
    color: #413f3f;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.286px;
  }
  .button {
    margin-top: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    border-radius: 10.37px;
    background: var(--Brand-color-3, #8d8d8d);
    width: 20vw;
    height: 51px;
    cursor: pointer;
    margin-left: 40vw;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  #loader {
    width: 100vw;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
