export default {
  getModal(state) {
    return state.modal;
  },
  getReviewModal(state) {
    return state.reviewModal;
  },
  getConfirmationModal(state) {
    return state.confirmationModal;
  },
  getOutstanding(state) {
    return state.outstanding;
  },
  getWeek(state) {
    return state.week;
  },
  getCurrPayment(state) {
    return state.currPayment;
  },
};
